<template>
    <div class="navbar">
      <div class="container">
      <div id="container-nav">
          
<!-- <div class="ham-icon" v-bind:class="{'hamicon-show':hideHam , 'hamicon-hide':activeHam}" ><button class="ham-button" v-on:click="shownav"></button></div> -->
    
    <button class="ham"  @click.stop="toggleHamburger">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
    
    <div class="image-ul">
                    <router-link to="/" ><img src="@/assets/Logo.png" class="logo" /></router-link>
                <div id="ul-div">
                    <ul id="navlist">
                        <li  ><router-link to="/about"><a href="#" v-bind:class="{'active-nav':$route.path == '/about'}">
                        درباره ما </a></router-link></li>
                        <li><router-link to="/contact"><a href="#" v-bind:class="{'active-nav':$route.path == '/contact'}">
                            تماس با ما</a></router-link></li>
                        <li><router-link to="/policy"><a href="" v-bind:class="{'active-nav':$route.path == '/policy'}">
                             قوانین و مقررات</a></router-link></li>
                        <li><router-link to="/FAQ"><a href="#" v-bind:class="{'active-nav':$route.path == '/FAQ'}">
                            سوالات متداول</a></router-link></li>
                        <li><router-link to="/blog"><a href="#" v-bind:class="{'active-nav':$route.path == '/blog'}">
                            بلاگ</a></router-link></li>
                    </ul>
                </div>
    </div>
                <div class="log-sub">
                    <img  src="@/assets/person.png" alt="">
                    <router-link to="/login">
                    <p class="login-btn">ورود</p>
                    </router-link>
                    <span>/</span>
                    <router-link to="/subscribe">
                    <p class="subscribe-btn">ثبت نام</p>
                    </router-link>
                </div>
                
                </div>
  </div>
  
<div class="overlay" ></div>
<nav class="sideNav" v-click-outside="onClickOutside">
    <button class="menuX"  @click.stop="toggleHamburger">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
     
    <ul>


<div class="log-sub-res">
                    <img  src="@/assets/person.png" alt="">
                    <router-link to="/login">
                    <p class="login-btn">ورود</p>
                    </router-link>
                    <span>/</span>
                    <router-link to="/subscribe">
                    <p class="subscribe-btn">ثبت نام</p>
                    </router-link>
                </div>
            <li><router-link to="/about"><a href="#">درباره ما </a></router-link>
            <hr>
            </li>
                        <li><router-link to="/contact"><a href="#">تماس با ما</a></router-link>
                        <hr>
                        </li>
                        <li><router-link to="/policy"><a href=""> قوانین و مقررات</a></router-link>
                        <hr>
                        </li>
                        <li><router-link to="/FAQ"><a href="#">سوالات متداول</a></router-link>
                        <hr>
                        </li>
                        <li><router-link to="/blog"><a href="#">بلاگ</a></router-link>
                        <hr>
                        </li>
    </ul>
  </nav>


</div>
<!-- </div> -->
</template>

<script>
import Vue2ClickOutside from 'vue2-click-outside'
export default {
  name: 'NavbarHome',
  components :{
    
  },
  directives: {
      clickOutside: Vue2ClickOutside.directive
    },
  data : function() {
      return {
          activeHam:false,
          hideHam:true
      }
  },
  methods : {
      shownav () {
          const hmaButton=document.getElementsByClassName("ham-button")[0]
          if ( this.activeHam===false) {
              this.activeHam=true;
                this.hideHam=false;
                hmaButton.classList.toggle("showClose")
          }
          else {
              this.activeHam=false;
                this.hideHam=true;
                hmaButton.classList.remove("showClose")
          }
          
      },
//       onClickOutside () {
//         const sideNav = document.querySelector(".sideNav")
// const overlay = document.querySelector(".overlay")
// overlay.classList.remove("showOverlay")
//   sideNav.classList.remove("showNav")
//       },
    //   hidenav () {
    //       this.activeHam=false;
    //       this.hideHam=true;
    //   }
    toggleHamburger () {
        const sideNav = document.querySelector(".sideNav")
const overlay = document.querySelector(".overlay")
overlay.classList.toggle("showOverlay")
  sideNav.classList.toggle("showNav")
    },
    onClickOutside () {
        const sideNav = document.querySelector(".sideNav")
const overlay = document.querySelector(".overlay")
overlay.classList.remove("showOverlay")
  sideNav.classList.remove("showNav")
      },
    
  }
  }
</script>

<style scoped>
    .ham-icon , .ham {
    display: none;
}
.showHamer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 1;
    top: 60px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}
.hiddenHamber {
    display: none;
}

.ham-button {
    position: absolute;
    top: 10px;
    height: 35px;
    width: 35px;
     border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../../assets/ham.svg");
  background-size: 100%;
}
.showClose {
  background-image: url("../../assets/closeHam.svg");
}
#navlist-ham {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
    row-gap: 10px;
}
#container-nav {
    height: 100%;
    width:100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */
}

.image-ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
#ul-div {
    display: inline-block;
    text-align: right;
}
.navbar {
    direction: rtl;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* background: rgba(21, 22, 25, 0.35); */
/* bg blur */

box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
backdrop-filter: blur(20px);
background: rgba(21, 22, 25, 0.35);
/* background: #191B22; */
position: sticky;
    left: 0;
    right: 0;
    z-index: 5;
    top: 0;
}
#navlist , li ,a {
    text-decoration: none;
    display: inline-block;
    /* margin: 10px; */
    color: white;
    font-family: shabnam;
    font-weight: 500;
font-size: 16px;
    
}

#navlist {
    /* margin: 5px 0 0 0; */
    display: flex;
    column-gap: 30px;
    align-items: center;
}

#subscrb {
width: 130px;
height: 40px;
background: #00ACC1;
border-radius: 79px;
}
#subscrib-button , #login-button {
    margin: 0;
}
#button-div {
    display: flex;
    column-gap: 10px;
    align-items: center;
}
#button-div-ham {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    column-gap: 20px;
}
.subscrib {
    width: 130px;
    height: 40px;
    background:  #058C2B;
    border-radius: 79px;
    border: 0;
    color: white;
    margin: 0 5px 0 0;

    border-radius: 35px;
  font-weight: 700;
}
.login {
        width: 130px;
        height: 40px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        /* margin: 0 5px 0 0; */
        border-radius: 35px;
        font-weight: 700;
    }
    .subscrib-responsive {
    width: 100px;
    height: 40px;
    background:  #058C2B;
    border-radius: 79px;
    border: 0;
    color: white;
    margin-bottom: 5px;

    border-radius: 35px;
  font-weight: 700;
}
.login-responsive {
        width: 100px;
        height: 40px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        /* margin: 0 5px 0 0; */
        border-radius: 35px;
        font-weight: 700;
    }
.log-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}
.log-sub-res {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}
.logo {
    width: 50px;
    height: 50px;
}
.log-sub > p , .log-sub > span {
    color: white;
    font-weight: 500;
font-size: 16px;
}
.login-btn , .subscribe-btn {
    cursor: pointer;
}

hr {
    height: 1px;
    width: 100px;
    background-image: linear-gradient(90deg ,black, white, black);
    border: none;
}
.active-nav {
    color: rgba(255, 176, 7, 1);
}



.hr-nav-responsive {
    height: 1px;
    width: 150px;
}

.ham {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background: none;

  border: none;
  cursor: pointer;
  color: white;
 
}
.menuX {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.sideNav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: black;
  width: 200px;
  height: 100vh;
  /* overflow: hidden; */
  overflow: scroll;
  color: white;
  /* hide the menu above the screen by default */
  transform: translateX(100%);
  transition: transform 0.4s ease;
  
}

.showNav {
  transform: translateX(0);
}

.sideNav ul {
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 4rem;
  padding-right: 0;
  row-gap: 50px;
}

.sideNav ul li a {
  display: block;
  color: black;
  /* padding-bottom: 10px; */
  /* margin-top: 1rem; */
  /* border-bottom: 2px solid black; */
  text-decoration: none;
  font-size: 1rem;
  display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.sideNav ul li a:hover, .sideNav ul li a:focus {
  border-bottom: 2px solid white;
}

.overlay {
  position: fixed;
  display: none;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
}

.showOverlay {
  display: block;
}


@media only screen and (max-width: 1300px) and (min-width: 901px) {
    /* #container-nav {
        justify-content: center;
    } */
    .navbar {
        height: auto;
        padding: 10px;
    }
}
@media only screen and (min-width: 900px) {
    #ul-div-ham , #button-div-ham {
        display: none;
    }
    
}
@media only screen and (max-width: 900px) {
    .ham-icon , .ham {
        display: inline-block;
    }
     #ul-div , #button-div , .log-sub{
        display: none;
    }
    /* #navbar {
        height: auto;
    } */
}
/* @media only screen and (max-width: 1440px) {
    #navbar {
        height: auto;
    }
    #container {
        display: grid;
        justify-content: center;
        align-items: center;
    }
    #logo-link ,#ul-div ,#button-div,#navlist {
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 700px) {
    #navlist {
        display: grid;
        text-align: center;
        padding: 0;
    }
    
} */
</style>
