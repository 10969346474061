<template>
    <div class="wallet">
        <p class="wallet_title">
            کیف پول
        </p>
        <div class="wallet-container">
            <div class="wallet_row">
                <div class="wallet_name_container">
                    <img
                    class="wallet_img"
                    :src="cryptoList[0].logo"
                    alt=""
                  />
                    <span class="wallet_label">
                       <span>تومان</span> 
                    </span>
                    <span class="wallet_code">
                        
                    </span>
                </div>
                <div class="amount">
                    <span > </span>
                    <span class="unit">
                        <span v-if="cryptoList[0].credit">{{cryptoList[0].credit.toLocaleString()}}</span> 
                        <span v-else>0</span>
                         تومان    
                    </span> 
                    
                </div>
                <div class="wallet_buttons">
                    <button class="wallet_btn toman_deposit_btn" @click="TomanDep">
                        واریز
                    </button>
                    <button class="wallet_btn toman_withdrawal_btn" @click="TomanWith">
                        برداشت
                    </button>
                </div>
            </div>
            <template v-for="(crypto , index) in cryptoList">
            <div class="wallet_row"  :key="index" v-if="crypto.code != 'TOMAN'">
                <div class="wallet_name_container">
                    <img
                    class="wallet_img"
                    :src="crypto.logo"
                    alt=""
                  />
                    <span class="wallet_label">
                       <span v-if="crypto.label">{{crypto.label}}</span> 
                       <span v-else>{{crypto.name}}</span>
                    </span>
                    <span class="wallet_code">
                        {{crypto.code}}
                    </span>
                </div>
                <div class="amount">
                    <span v-if="crypto.credit"> {{crypto.credit}} </span>
                    <span v-if="!crypto.credit"> 0 </span>
                    <span class="unit"> {{crypto.code}} </span> 
                    
                </div>
                <div class="wallet_buttons">
                    <template  v-for="(button , index) in buttons" >
                        <button class="wallet_btn"  
                        :id=crypto.coin_id
                        :class=button.class
                        @click="button.click"
                        :key="index"
                        v-if="crypto.code!=button.for">
                            {{button.title}}
                        </button>
                    </template>
                </div>
            </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name:"wallet",
    components: {

    },
    data() {
        return {
            cryptoList:[],
            walletList : [
                {

                }
            ],
            modals: {
                deposit:false,
                withdrawal:false,
                TomanDeposit:false,
                TomanWithdrawal:false,
                id:null,
            },
            buttons:[
                {
                    title:"واریز",
                    class:"deposit_btn",
                    click:() => {
                        this.modals.deposit=true
                        this.modals.id=event.srcElement.id
                        console.log('modals' , this.modals.id)
                        this.$emit('sendId' , this.modals)
                    }
                },
                {
                    title:"برداشت",
                    class:"withdrawal_btn",
                    click:() => {
                        this.modals.withdrawal=true
                        this.modals.id=event.srcElement.id
                        console.log('modals' , this.modals.id)
                        this.$emit('sendId' , this.modals)
                    }
                },
                {
                    title:"معامله",
                    class:"deal_btn",
                    for:'TOMAN',
                    click:() => {
                        this.$router.push('exchange')
                    }
                }
            ],
            cryptoImages: {
                TOMAN : {
                    img: require("../../assets/Crypto/ADA.png"),
                },
                ADA: {
                    img: require("../../assets/Crypto/ADA.png"),
                },
                BCH: {
                    img: require("../../assets/Crypto/BCH.png"),
                },
                BNB: {
                    img: require("../../assets/Crypto/BNB.png"),
                },
                BTC: {
                    img: require("../../assets/Crypto/BTC.png"),
                },
                DOGE: {
                    img: require("../../assets/Crypto/DOGE.png"),
                },
                EOS: {
                    img: require("../../assets/Crypto/EOS.png"),
                },
                ETC: {
                    img: require("../../assets/Crypto/ETC.png"),
                },
                ETH: {
                    img: require("../../assets/Crypto/ETH.png"),
                },
                LTC: {
                    img: require("../../assets/Crypto/LTC.png"),
                },
                NEO: {
                    img: require("../../assets/Crypto/NEO.png"),
                },
                TRX: {
                    img: require("../../assets/Crypto/TRX.png"),
                },
                XLM: {
                    img: require("../../assets/Crypto/XLM.png"),
                },
                XMR: {
                    img: require("../../assets/Crypto/XMR.png"),
                },
                XRP: {
                    img: require("../../assets/Crypto/XRP.png"),
                },
                USDT: {
                    img: require("../../assets/Crypto/USDT.png"),
                },
                BSV: {
                    img: require("../../assets/Crypto/BSV.png"),
                },
                DAI: {
                    img: require("../../assets/Crypto/BSV.png"),
                },

            },
        }
    },
    methods:{
         async wallet() {
             this.state.loading=true
             const res = await this.$axios.get("/credits",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                 console.log('res', res)
                 this.state.loading=false
                 this.cryptoList=res.data.data
         },
         TomanDep() {
             this.modals.TomanDeposit = true
             this.$emit('sendId' , this.modals)
         },
         TomanWith() {
             this.modals.TomanWithdrawal = true
             this.$emit('sendId' , this.modals)
         }
    },
    mounted() {
        this.wallet()
    }
}
</script>
<style lang="scss" scoped>
    .wallet {
        width: 95%;
        margin: auto;
        flex-grow: 1;
    }
    .wallet_title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
    }
    .wallet-container {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
    .wallet_row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(21, 22, 26, 1);
        min-height: 70px;
        padding: 0 15px;
        border-radius: 5px;
        flex-wrap: wrap;
        row-gap: 30px;
        padding: 10px;
    }
    .wallet_img {
        width: 40px;
        height: 40px;
    }
    .wallet_label {
        color: #FFFFFF;
        font-size: 14px;
    }
    .wallet_code {
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
    }
    .wallet_name_container {
        display: flex;
        align-items: center;
        column-gap: 5px;
        width: 200px;
    }
    .wallet_buttons {
        display: flex;
        column-gap: 5px;
        flex-wrap: wrap;
    }
    .wallet_btn {
        border: none;
        border-radius: 5px;
        color: white;
        width: 90px;
        height: 30px;
    }
    .deposit_btn {
        color: #0FA902;
        background: transparent;
        border: 1px solid #0FA902;
    }
    .withdrawal_btn {
       color: #FF2E21;
        background: transparent;
        border: 1px solid #FF2E21;
    }
    .deal_btn {
         background: transparent;
         border: 1px solid rgba(255, 176, 7, 1);
         color: rgba(255, 176, 7, 1);
    }
    .amount {
        color: #FFFFFF;
            .unit {
                color: #f7f7f7;
            }
    }
    .toman_withdrawal_btn {
         background: #FF2E21;
         width: 135px;
    }
    .toman_deposit_btn {
        background: #0FA902;
        width: 135px;
    }
</style>