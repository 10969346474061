<template>
    <div class="authentication">
        <NavbarHome v-if="$route.path=='/authentication'"/>
        <div class="container">
            <div>
                <p class="authentication-title" v-if="$route.path=='/authentication'">
                    احراز هویت
                </p>
                <p class="authentication-title" v-if="$route.path=='/account'">
                    حساب کاربری
                </p>
            </div>
            <PersonalInformation v-model='personalData'  :sendError.sync='PersonalErrors' :key="reload"/>
            <!-- @sendPersonal="setPersonalInfo" -->
            <!-- :sendError.sync='PersonalErrors' -->
            <ContactInformation v-model='personalData' @childData="setPersonal" :sendError.sync='PersonalErrors' :key="reload"/>
            <BankInformation @fromBank="checkBank"/>
            <div class="authen-part document">
                <div class="title">
                    <p>آپلود مدارک</p>
                </div>
                <div class="describe-upload-container">
                    <p class="describe-upload">
                        عکس مدارک خود را که شامل عکس سلفی ، کارت بانکی و کارت ملی است را در فیلد های مربوط به خود آپلود کنید.
                    </p>
                    <p class="describe-upload">
                        در برگه حتما باید متن زیر را بنویسید
                    </p>
                    <p class="describe-upload">
                        طفا قبل از ارسال فایل احراز هویت مطالب زیر را مطالعه کنید 
                    </p>
                    <p class="describe-upload">
                        فایل باید حتما طبق نمونه ارسال گردد
                    </p>
                    <p class="describe-upload">
                        حتما مدارک را همراه با برگه نوشته با متن طبق نمونه در دسـت بگـیرید
                    </p>
                    <p class="describe-upload">
                        عکس ها سلفی بدون برگه نوشته توضیحات و تاریخ تایید نخواهد شد
                    </p>
                    <p class="describe-upload">
                        رای احراز هویت ارسال عکس تنها از کارت بانکی یا شناسایی قابل قبول نیست
                    </p>
                    <p class="describe-upload">
                        صورت شما و نوشته های کارت باید در عکس سلفی واضح باشد
                    </p>
                    <p class="describe-upload">
                        مـدارک ارسـالی به صـورت اسـکن شـده قـابل قـبول نیـست و حـتما باید با موبایل عکس رفته شده باشد
                    </p>
                    <p class="describe-upload">
                         تاریخ روی برگه باید همان روز ارسال باشد 
                    </p>
                </div>
                <div class="upload-text">
                    <p>
                        این جانب ........... با شماره ملی ....... در تاریخ ........... مدارک خود را جهت احراز هویت در سایت ادا تتر ارسال و اعلام می کنم مدارک، حساب کاربری و اطلاعات کارت بانکی خود را در اختیار دیگری قرار نداده ام و متعهد می شوم از دریافت وجه و خرید ارز دیجیتال برای اشخاص دیگر تحت هر عنوانی )آگهی استخدام، کسب سود، کاریابی در منزل و ....( خودداری کنم. در غیر این صورت چنانچه وجهی از سوی دیگران برای خرید به حسابم واریز شد ، متوجه هستم که ممکن است وجه واریزی سرقتی باشد و مسوولیت حقوقی و قضایی آن اعم از مجاز ات قانونی و .جبران ضرر و زیان شاکی بر عهده بنده خواهد بود.
                    </p>
                </div>
                <div class="warning">
                    <img class="warning-img" src="@/assets/warning.png" alt="">
                    <p class="warning-text">
                        توجه : متن باید به صورت دستی نوشته شود و از تایپ کردن سیستمی خودداری نمائید. در صورت در دسترس نبودن کارت ملی برای شناسایی ، استفاده از کارت گواهینامه معتبر یا شناسنامه جدید بلامانع است
                    </p>
                </div>
                <div class="iamge-upload">
                    <div>
                        <img class="upload-education" src="@/assets/uploadEducation.png" alt="">
                    </div>
                    <div class="upload-container">
                        <div>
                        <div class="upload ">
                            <img  v-if="imgCard != ''" :src='imgCard' alt="" class="uploaded-img">
                            <img v-else class="cloud" src="@/assets/cloud.png" alt="">
                            <label for="upload-file" class="upload-btn"> تصویر کارت ملی
                            </label>
                            <input id="uploa-file" type="file" @change="cardImage">
                        </div>
                        <p class="error-p">
                            {{imgSellErr}}
                            </p>
                        </div>
                        <div>
                        <div class="upload " >
                            <img v-if="imgSell != ''" :src='imgSell' alt="" class="uploaded-img" >
                            <img v-else class="cloud" src="@/assets/cloud.png" alt="" >
                            
                            <label for="upload-file" class="upload-btn"> تصویر  سلفی
                            </label>
                            <input id="uploa-file" type="file" @change="selfieImage">
                        </div>
                        <p class="error-p">
                            {{imgCardErr}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="error-p authen-error">
                {{mainErr}}
            </p>
            <div class="buttons">
                <!-- <router-link to="/dashboard"> -->
                <button class="authen-btn" @click="mainPost" v-if="$route.path=='/authentication'">
                    احراز هویت
                </button>
                <!-- </router-link> -->
                <!-- <button class="reject-btn" @click="reject" v-if="$route.path=='/authentication'">
                    رد کردن
                </button> -->
                <button class="change-btn" v-if="$route.path=='/account'" @click="changeInfo">
                    اعمال تغییرات
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarHome from '../HomePage/NavbarHome.vue'
import PersonalInformation from './PersonalInformation.vue'
import ContactInformation from './ContactInformation.vue'
import BankInformation from './BankInformation.vue'
export default {
  name: 'Authentication',
  components :{
    NavbarHome,
    PersonalInformation,
    ContactInformation,
    BankInformation
  },
  data() {
      return {
         time1: null,
         mainErr:'',
         imgCard:"",
         imgSell:"",
         imgCardErr:"",
         imgSellErr:"",
         reload:false,
         bankInfo : '',
         PersonalErrors:{
             name : "",
             national_code : "",
             birthday : "",
             address:"",
             phone:"",
             mobile:"",
            reload:false
         },
         test: {
             name:"aaaaaaaa"
         },
        //  data:[],
         someData:"",
         imggg : "",
         selfieError:"",
         cardError:"",
         personalData: { 
             name:'',
             national_code:"",
             birthday:"",
             address:"",
             phone:"",
             mobile:"",
             email:"",
               
         },
         NATIONAL:{
             document:'',
             type:'NATIONAL_CARD'
         },
          SELFIE:{
              document:'',
             type:'SELFIE'
          }
       
      }
  },
  methods: {
      reject() {
          this.state.authentication=false
          this.$router.push('/dashboard')
      },
      checkBank(value) {
          this.bankInfo = value
      },
     async personalinfo() {
                
                const res = await this.$axios.get("/authentication",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                
                this.personalData=res.data.data
                this.reload = !this.reload
                console.log('personalData:',this.personalData)
                // this.personalData.name = res.data.data.name ? res.data.data.name : ''
            },
    async documents() {
        const document = await this.$axios.get("/documents",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                 console.log('documents',document)
                 this.imgCard = document.data.data[0].image
                 this.imgSell = document.data.data[1].image
    },
    async banks() {
        const bank = await this.$axios.get("/accounts",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                 console.log('banks',bank)
                 this.Banks=bank.data.data
    },
    // setPersonalInfo(value) {
    //     this.personalData = value
    //     console.log(this.personalData)
    // },
    mainPost() {
        if(this.imgCard != '' && this.imgSell != '' && this.bankInfo != '') {
            this.postInfo()
        }
        else {
            this.imgCardErr = 'بارگزاری تصویر کارت ملی الزامی است'
            this.imgSellErr = 'بارگزاری تصویر  سلفی الزامی است'
            this.mainErr = 'داشتن حداقل یک حساب بانکی الزامی است'
        }
    },
    async postInfo() {
        this.PersonalErrors.name = ''
        this.PersonalErrors.national_code  = ''
        this.PersonalErrors.birthday = ''
        this.PersonalErrors.address = ''
        this.PersonalErrors.phone = ''
        this.PersonalErrors.mobile  = ''
        this.imgCardErr = ''
            this.imgSellErr = ''
            this.mainErr = ''
        this.state.loading = true
        console.log('dddd',this.personalData)
        try {
            const send= await this.$axios.post('/authentication', this.personalData ,
    {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
         console.log(send , this.personalData.name)
         this.state.loading = false
        this.state.success = true
        this.$cookies.set('userStatus' , send.data.data.user_status)
        this.$router.push('/finnotech')
        }
        catch(err) {
            const error = err.response || err.toJSON();
            console.log(error.data)
            this.mainErr = error.data.message
            // let obj = {
            //     name : error.data.errors.name
            // }
            console.log('test test');
            // this.PersonalErrors = {}
            // this.PersonalErrors = obj

            console.log(error.data.errors.name);
            if(error.data.errors.name) {
                this.PersonalErrors.name = error.data.errors.name[0]
            }
            if(error.data.errors.national_code) {
                this.PersonalErrors.national_code = error.data.errors.national_code[0]
            }
            if(error.data.errors.birthday) {
                this.PersonalErrors.birthday = error.data.errors.birthday[0]
            }
            if(error.data.errors.address) {
                this.PersonalErrors.address = error.data.errors.address[0]
            }
            if( error.data.errors.phone) {
                this.PersonalErrors.phone = error.data.errors.phone[0]
            }
            if(error.data.errors.mobile) {
                this.PersonalErrors.mobile = error.data.errors.mobile[0]
            }
            
            this.PersonalErrors.reload = !this.PersonalErrors.reload
             this.state.loading = false
            //  console.log('err',this.PersonalErrors);
        }
        this.state.loading = false
    },
    setPersonal(value) {
        this.personalData=value
    },
    async cardImage(event) {
        this.NATIONAL.document = event.target.files[0]
        console.log('his.NATIONAL.docume',event.target.files);
        const img = new FormData()
        img.append('document' , this.NATIONAL.document )
        img.append('type',this.NATIONAL.type)
        // // console.log('his.NATIONAL.docume',img);
        // this.imggg = img.append('document' , this.NATIONAL.document )
        // this.NATIONAL.document = img
        // {
        //         document : img,
        //         type : this.NATIONAL.type
        //     } ,
        try {
            this.state.loading = true
           await this.$axios.post('/documents', img,
        {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
        this.imggg = img.document
        console.log('this.imggg' , this.imggg)
         this.state.loading = false
         this.state.success = true
        this.documents()
        }
        catch(err) {
            const error = err.response || err.toJSON();
             this.cardError = error.data.errors.document[0]
            console.log(error)
        }
        
        // console.log(this.someData)
    },
    async selfieImage(event) {
        this.SELFIE.document = event.target.files[0]
        console.log('his.NATIONAL.docume',event.target.files);
        const img = new FormData()
        img.append('document' , this.SELFIE.document )
        img.append('type',this.SELFIE.type)
        try {
            this.state.loading = true
            await this.$axios.post('/documents', img,
        {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
        
        console.log('this.imggg' , this.imggg)
        this.state.loading = false
        this.state.success = true
        this.documents()
        }
        catch(err) {
            const error = err.response || err.toJSON();
            this.selfieError = error.data.errors.document[0]
            console.log('error',error)
        }
    },
    
    // removeImage: function (e) {
    //   this.image = '';
    // },

    // createImage(file) {
    // //   var image = new Image();
    // //   var reader = new FileReader();
    // //   var vm = this;

    // //   reader.onload = (e) => {
    // //     Image() = e.target.result;
    // //   };
    //   reader.readAsDataURL(file);
    // },
    async changeInfo() {
        const change = await this.$axios.put('/authentication', this.personalData ,
            {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
            console.log(change)
    }
       },
       mounted() {
            this.personalinfo()
            this.documents()
            this.banks()
            // this.personalData=this.state.email
        },
  }
</script>

<style scoped>
.error-p {
    text-align: center;
}
.authen-error {
    font-size: 20px;
}
.change-btn {
      width: 100%;
      max-width: 335px;
      background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border-radius: 5px;
        border: none;
        height: 40px;
        font-weight: 500;
        font-size: 16px;
  }
    .authentication {
        min-height: 2300px;
        background: #2F3239;
    }
    .authentication-title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
    }
  .container {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      
  }
  .authen-part {
      border-radius: 5px;
      background: #1D1F23;
  }
  .title{
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .uploaded-img {
      width: 100%;
      height: 150px;
  }
  .describe {
      font-weight: normal;
/* font-size: 14px; */
font-size: clamp(12px , 2vw , 14px);
text-align: right;
color: #FFFFFF;
width: 95%;
margin: auto;

  }
  .inputs {
      display: flex;
      width: 95%;
      margin: auto;
      column-gap: 100px;
      align-items: center;
      margin-top: 30px;
      padding-bottom: 30px;
  }
  label , input , textarea{
      width: 100%;
     border: none;
  }
  input  {
       background: rgba(255, 255, 255, 0.04);
        border-radius: 5px;
        height: 40px;
        color:rgba(255, 255, 255, 0.7)
  }

  .textarea {
      width: 95%;
      margin: auto;
      color:rgba(255, 255, 255, 0.7)
  }
  textarea {
       background: rgba(255, 255, 255, 0.04);
        border-radius: 5px;
        min-height: 91px;
        resize: none;
        color:rgba(255, 255, 255, 0.7);
        padding: 10px;
  }
  label {
      position: relative;
      font-weight: normal;
        /* font-size: 16px; */
        font-size: clamp(14px , 2vw , 16px );
        color: white;
        }
  .calender {
      position: absolute;
      top: 35px;
      left: 10px;
  }
  .add-bank {
      background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border-radius: 5px;
        width: 122px;
        height: 40px;
        border: none;
        margin-top: 30px;
        font-weight: 600;
        }
  .bank-inputs {
      border-bottom: 1px solid #2F3239;
      padding-bottom: 20px;
  }
  .table-container {
      overflow-x:scroll ;
  }
  .table-container::-webkit-scrollbar {
      display: none;
  }
  .table-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
  table {
      width: 95%;
      margin: auto;
      text-align: center;
      min-width: 600px;
  }
  tr {
      font-weight: 500;
        font-size: 16px;
        height: 60px;
  }
  tr.head {
      color: rgba(255, 176, 7, 1);
  }
  tr .name , .shaba , .cardNumber , .account {
      color: #FFFFFF;
  }
  tr .name {
      display: flex;
      align-items: center;
      column-gap: 20px;
  }
  tr .name-head {
      width: 100px;
  }
  .trash {
      cursor: pointer;
  }
  .bank-image {
      width: 30px;
      height: 30px;
      
  }
  .authen-btn , .reject-btn {
      width: 122px;
      height: 40px;
      text-align: center;
      border-radius: 5px;
  }
    .describe-upload {
        font-weight: normal;
        /* font-size: 14px; */
        font-size: clamp(12px , 2vw , 14px);
        color: white;
        width: 95%;
        margin: 5px auto;
    }
    .upload-text {
        width: 95%;
        margin: 40px auto 20px auto;
        font-weight: normal;
        /* font-size: 14px; */
        font-size: clamp(12px , 2vw , 14px);
        color:rgba(255, 255, 255, 0.7)
    }
    .describe-upload-container {
        margin: 20px 0;
    }
    .iamge-upload {
        display: flex;
        justify-content: flex-end;
        column-gap: 40px;
        margin: 40px 60px;
    }
    .upload-container {
        display: flex;
        column-gap: 50px;
        row-gap: 20px;
    }
    .upload {
        width: 214px;
        height: 214px;
        display: flex;
        flex-direction: column;
        align-items: center;
    justify-content: space-between;
    background: #2F3239;
border-radius: 5px;
border: 1px dashed #FF9C07;
position: relative;
cursor: pointer;
    }
    .upload-btn {
        width: 100%;
        height: 40px;
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border-radius: 5px;
        border: none;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        color: rgba(21, 22, 26, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cloud {
        width: 110px;
        height: 103px;
        margin: auto;
    }
    #uploa-file {
        opacity: 0;
        position: absolute;
        right: 0;
        height: 100%;
    }
  .buttons {
      display: flex;
      justify-content: center;
      column-gap: 40px;
      flex-wrap: wrap;
      margin-bottom: 20px;
      row-gap: 20px;
  }
  .authen-btn {
    background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
    font-weight: 500;
    font-size: 16px;
    border: none;
  }
  .reject-btn {
      background: transparent;
      color: white;
      border: 1px solid #FF9C07;
  }
  .warning {
      display: flex;
      column-gap: 20px;
      /* align-items: center; */
      width: 95%;
      margin: auto;
  }
  .warning-img {
      width: 30px;
      height: 30px;
      margin-top: 15px
  }
  .warning-text {
      font-weight: normal;
        /* font-size: 14px; */
        font-size: clamp(12px , 2vw , 14px);
        color: white;
  }
  @media only screen and (max-width:992px) {
      .inputs , .iamge-upload , .upload-container{
          flex-wrap: wrap;
    justify-content: center;
      }
  }
</style>
<style >
    .mx-datepicker , .mx-input {
       background: rgba(255, 255, 255, 0.04) !important;
        border-radius: 5px !important;
        height: 40px !important;
        color:rgba(255, 255, 255, 0.7) !important;
        border: none !important;
        width: 100% !important;
  }
  .mx-icon-calender , .mx-icon-clear {
      display: none !important;
  }
  .mx-icon-calender > svg {
      display: none !important;
      fill: transparent !important;
  }
  .mx-icon-calendar, .mx-icon-clear {
    display: none;
}
</style>
