<template>
  <div class="container">
      <div class="blogItems-title-container">
          <p class="blogItems-title">آخرین مطالب بلاگ</p>
          <hr>
      </div>
    <carousel rtl='true'>
    <slide v-for="(Items , index) in BlogItems" :key="index">
      <div class="blog-item">
          <div>
              <img src="@/assets/blog.png" alt="">
          </div>
          <div class="blog-text">
              <p class="item-title">{{Items.title}}</p>
              <p class="item-discribe">{{Items.discribe}}</p>
          </div>
      </div>
    </slide>
  </carousel>
</div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import Items from "../../library/BlogItems.js"
export default {
  name: 'slider',
  components :{
    Carousel,
    Slide,
  },
  data() {
    return {
      BlogItems:Items
    }
  },
}
</script>
<style scoped>
  .slide {
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(20px);
    border-radius: 5px;
    width: 305px;
    height: 91px;
    display: flex;
    justify-content: space-between;
  }
  hr {
      height: 1px;
      width: 250px;
      background-image: linear-gradient(90deg, transparent , white , transparent);
      border: 0;
  }
  .blogItems-title {
      text-align: center;
      font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;

  }
  .blog-item{
    width: 244px;
    height: 280px;
    background: #151619;
  }
  .blog-text {
      padding: 10px 10px 40px 10px;
  }
  .item-title {
      font-weight: 500;
        font-size: 16px;
        color: #DBABA7;
  }
  .item-discribe {
      font-weight: normal;
        font-size: 14px;
        color:rgba(255, 255, 255, 0.6);
        
  }
  .blogItems-title-container {
    margin-bottom: 60px;
  }
</style>

