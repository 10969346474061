<template>
    <div class="login">
        <div class="login-form-title">شناسایی دو عاملی</div>
        <div>
            <hr>
        </div>
        <!-- <form action=""> -->
            <div>
                <label for="email_sms" v-if="toOtpLogin.method == 'EMAIL'">ایمیل</label>
                <label for="email_sms" v-if="toOtpLogin.method == 'SMS'">موبایل</label>
                    <input name = 'email_sms' v-if="toOtpLogin.method == 'EMAIL'" type="text" v-model="data.email" disabled>
                    <input name = 'email_sms' v-if="toOtpLogin.method == 'SMS'" type="text" :value="toOtpLogin.mobile" disabled>
                
            </div>
            <div>
                <label for="code_input" v-if="toOtpLogin.method == 'EMAIL'"> کد ارسال شده به ایمیل
                    <span class="second">{{this.second}}</span>
                </label>
                <label for="code_input" v-if="toOtpLogin.method == 'GOOGLE'"> کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید. 
                    <span class="second">{{this.second}}</span>
                </label>
                <label for="code_input" v-if="toOtpLogin.method == 'SMS'">کد ارسال شده به موبایل را وارد نمایید
                    <span class="second">{{this.second}}</span>
                </label>
                    <input name= "code_input" type="text"  v-model="data.code">
                   
                    
                    <!-- @input="checkCode" -->
                    <p class="error-p">
                        {{ErrorText}}
                    </p>
                
            </div>
            <div>
                <button v-if="second==0" class="login-btn" @click="reSend">دریافت مجدد کد</button>
                <button v-else class="login-btn" @click="send">تایید کد</button>
            </div>
        <!-- </form> -->
    </div>
</template>

<script>
export default {
  name: 'OTPLogin',
  components :{
    
  },
  props : [
      'toOtpLogin'
  ],
  data() {
      return {
          second:60,
          emailCode:null,
          resendBtn:false,
          ErrorText:"",
          data:{
              email:this.toOtpLogin.email,
              code:null
          }
      }
  },
  methods: {
    inputCode() {
        if (this.second > 0) {
                    this.timeOut = setTimeout(() => {
                        this.second--
                        this.inputCode()
                    }, 1000)
                } 
      },
      async send() {
          this.state.loading = true
        try {

       
          const res =await this.$axios.post('/check-otp', this.data)
          if(res.data.status==200) {
              this.$cookies.set('token' , res.data.data.token)
              
              console.log( this.data , res.data.data.token)
              
          }
           const userStatus = await this.$axios.get(
              '/users/init' ,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          await this.$cookies.set('userStatus' , userStatus.data.data.status)
          this.$router.push('/authentication')
         }
         catch(err) {
             const res = err.response || err.toJSON();
            //  if(res.data.error) {
            //      this.ErrorText = res.data.data.errors.code;
            //  }
             
             this.ErrorText = res.data.message
             this.state.loading = false
         }
          this.state.loading = false
      },
      async getStatus() {
          const res = await this.$axios.get(
              '/authentication' ,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          this.$cookies.set('userStatus' , res.data.data.status)
      },
      async reSend() {
          this.state.loading = true
          this.resendBtn=false
          this.second=60
          
      try {
            await this.$axios.post(
        "/resend-otp",
        this.data
      );
      this.inputCode()
        }
        
        catch(err) {
            const res = err.response || err.toJSON();
            console.log(res.data.errors.email);
            this.ErrorText = res.data.message;
        }
        this.state.loading = false
    }
  },
  beforeDestroy() {
            clearTimeout(this.timeOut)
        },
  mounted() {
      this.data.email=this.state.email
      this.inputCode()
  },
  }
</script>

<style scoped>
.login , form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
    .login-form-title {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
    }
    hr {
        border: 0;
        height: 1px;
        background-image: linear-gradient(90deg , black , white , black);
    }
    label , input {
        width: 100%;
        /* background: #212225; */
        border-radius: 5px;
        border: 0;
    }
    label {
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
        position: relative;
    }
    input {
        height: 40px;
        color: rgba(255, 255, 255, 0.7);
        background: rgba(255, 255, 255, 0.04);
        
    }
    .login-btn {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border: 0;
        font-weight: 500;
font-size: 16px;
    }
    .sub-link {
        font-weight: normal;
font-size: 14px;
color: #FFFFFF;

    }
    .link-p {
        text-decoration-line: underline;
        color: #53ACFF;
    }
    .google-btn {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        background: transparent;
        color: #FFFFFF;
        height: 50px;
    }
    .forget-a {
        margin: 0;
        text-align: left;
    }
    .forget-a > a {
        text-decoration-line: underline;
        color: #FFFFFF;
        font-weight: normal;
        font-size: 12px;
    }
    .second {
        border: 0;
        background: #2B2D30;
        position: absolute;
        width: 45px;
        left: 1px;
        top: 26px;
        height: 38px;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
</style>
