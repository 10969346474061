<template>
    <div class="notification">
        <p class="notification-title">
            اعلانات
        </p>
        <div class="question-answer" v-for="(Announcement , index) in AnnouncementList" :key="index" :class="{'readed' : Announcement.is_read}">
            
                    <div class="question" @click="reade(index)">
                        <p class="question-text"><img src="@/assets/dote.png" alt="">{{Announcement.title}}</p>
                        <p class="date">{{$G2J(Announcement.created_at)}}</p>
                        <img :class="{'rotate-up':Announcement.rotate}" src="@/assets/caretdown.png" alt="">
                    </div>
                    <transition name="fade">
                    <div class="answer showAnswer" v-if="Announcement.hideAnswer">
                        <!-- :class="{'hideAnswer':hideAnswer , 'showAnswer':!hideAnswer}" -->
                        <p class="answer-text">
                            {{Announcement.body}}
                        </p>
                    </div>
                    </transition>
                </div>
    </div>
</template>

<script>

export default {
  name: 'Notification',
  components :{
   
  },
  data() {
      return {
          hideAnswer:false,
            rotate:false,
            AnnouncementList:[]
      }
  },
  methods :{
        async notification() {
          this.state.loading = true
          const res = await this.$axios.get(
              '/notifications',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
            this.AnnouncementList = res.data.data
            this.AnnouncementList.forEach(element => {
                element.hideAnswer=false
                element.rotate=false
            });
            this.state.loading = false
            console.log('AnnouncementList',this.AnnouncementList);
      },
       async reade(index) {
          this.AnnouncementList[index].hideAnswer=!this.AnnouncementList[index].hideAnswer
           this.AnnouncementList[index].rotate=!this.AnnouncementList[index].rotate
           if(!this.AnnouncementList[index].is_read) {
             await   this.$axios.patch(
              '/notifications/'+this.AnnouncementList[index].id,null,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
           }
           
          this.$forceUpdate()
      }
  },
  mounted() {
      this.notification()
  }
  }
</script>

<style scoped>
    .notification {
        width: 95%;
        margin: auto;
        row-gap: 10px;
        display: flex;
        flex-direction: column;
    }
    .notification-title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
    }
    .question-answer {
        height: auto;
        background: rgba(21, 22, 26, 0.7);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        overflow:hidden;
        height:max-content;
    }
    .question {
        width: 100%;
        /* height: 60px; */
        background: rgba(21, 22, 26, 0.7);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 60px;
        row-gap: 20px;
    }
    .question > p , .question > img {
        margin: 0 30px;
    }
    .question-text {
        font-weight: 500;
        /* font-size: 18px; */
        font-size: clamp(14px , 2vw , 16px);
        color: white;
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
    .date {
        font-weight: 500;
font-size: 16px;
color: rgba(244, 244, 244, 0.7);
    }
    .answer {
        width: 95%;
        margin: 10px auto;
        background: rgba(255, 255, 255, 0.05);
        height: max-content;
        /* transform: scaleY(1); */
        height:100px;
        max-height: 100%;
    }
    .answer-text {
        font-weight: normal;
        /* font-size: 14px; */
        font-size: clamp(12px , 2vw , 14px);
        color: #FFFFFF;
        width: 95%;
        padding: 0 10px;
    }
    .hideAnswer {
        display: none;
    }
    .showAnswer {
        display: inline-block;
    }
    .rotate-up {
        transform: rotate(-180deg);
         transition: transform 0.2s ease;
    }
    .fade-enter-active, .fade-leave-active {
  transition: height 0.8s ease-out;
}

.fade-enter, .fade-leave-to {
  height:0;
}
.readed {
    opacity: 0.7;
}
/* .fade-enter {
    max-height: 500px;
} */
</style>
