<template>
    <transition name="modal-fade" >
        <div class="modal-backdrop" >
          <div class="modal">
            <div class="message-title">
                <img class="logo" src="@/assets/ada.png" alt="">
                 لطفا منتظر بمانید...
            </div>
          </div>
        </div>
    </transition>
</template>

<script>

export default {
  name: 'Loading',
  components :{
    
  },
  data() {
      return {
        
      }
  },
  methods : {
  },
  }
</script>

<style lang="scss" scoped>
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(10, 10, 10, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 210;
  }
  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 450px;
    min-height: 150px;
    background: transparent;
    border-radius: 8px;
    position: relative;
    padding: 24px;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
    justify-content: center;
  }
  .message-title {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
    flex-direction: column;
  }
  .logo {
      width: 300px;

  }
</style>
