<template>
  <div class="exchange">
    <Sell v-model="tradeData" v-if="SellModal" @close="SellModal = false" />
    <div class="exchange-title">
      <p>معاملات</p>
    </div>
    <div class="excahnge-table">
      <div class="exchange-inputs">
        <div class="exchange-inputs-title">
          <div class="buy-sell">
            <div
              :class="{ 'active-buy': this.buy, 'deActive-buy': this.sell }"
              @click="activeBuy"
            >
              خرید
            </div>
            <div
              :class="{ 'active-sell': this.sell, 'deActive-sell': this.buy }"
              @click="activeSell"
            >
              فروش
            </div>
          </div>
        </div>
        <div class="exchange-inputs-container">
          <div class="buy-sell-form">
            <label for="" class="coin-type-label"
              >نوع ارز
              <div class="selected-crypto">
                <!-- <img v-if="selected.length!=0" :src=cryptoImages[selected.code].img alt="" /> -->
                <span>{{ selected.label }}</span>
                <span>{{ selected.code }}</span>
              </div>
              <img
                class="drop-img coin-drop"
                src="@/assets/caretdown.png"
                alt=""
                @click="showCoins"
              />

              <div class="search-coins" v-if="coinsDropdown">
                <div class="myInput-container">
                  <input type="text" id="myInput" title="Type in a name" />
                </div>
                <ul id="myUL">
                  <li>
                    <div
                      class="drop-row"
                      v-for="(crypto, index) in cryptoList"
                      :key="index"
                      @click="select(index)"
                    >
                      <img :src="crypto.coin.logo" alt="" />
                      <span class="drop-name">{{ crypto.coin.label }}</span>
                      <span class="drop-ENname">{{ crypto.coin.code }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              
            </label>
            
            <label for="">
              مقدار مورد نظر
              <input type="text" v-model="tradeData.amount" @input="calcPrice"/>
              <p class="error-p">
                {{ErrorText.amount[0]}}
              </p>
            </label>
            <label for="">
              مبلغ کل معامله
              <input type="text" v-model="totalPrice" disabled/>
            </label>
            
            <p class="form-text">
              کارمزد انتقال:
              <span class="form-text-amount"
                >{{ this.information[0].wage.toLocaleString() }} تومان
              </span>
            </p>
            <p class="form-text">
              حداقل تعداد خرید {{ selected.label }}:
              <span class="form-text-amount">
                {{ this.information[0].minBuy.toLocaleString() }}
                <span>{{ selected.code }}</span></span
              >
            </p>
            <p class="form-text">
              حداکثر تعداد خرید  {{ selected.label }}:
              <span class="form-text-amount">
                {{ this.information[0].maxBuy.toLocaleString() }} {{ selected.code }}
              </span>
            </p>
            <button v-if="this.buy" class="buy-bit-btn" @click="trade" :disabled="!selected.code" :class="{'disable-btn' : !selected.code}">
              خرید {{ selected.label }}
            </button>
            <button v-if="this.sell" class="sell-bit-btn" @click="trade">
              فروش {{ selected.label }}
            </button>
          </div>
        </div>
      </div>
      <div class="table-container">
        <table>
          <tr class="head-row">
            <td v-for="(heade, index) in tr_heade" :key="index">
              <p class="title-p-box">
                {{ heade.title }}
                <span class="title-sub-box" v-if="heade.subTitle">{{
                  heade.subTitle
                }}</span>
              </p>
            </td>
          </tr>
          <tr class="row1" v-for="(crypto, index) in cryptoList" :key="index">
            <template v-if="crypto.coin.code != 'TOMAN'">
              <td>
                <div class="first-td-crypto">
                  <img
                    class="box-image"
                    :src="crypto.coin.logo"
                    alt=""
                  />
                  <p class="crypto-name">{{ crypto.coin.label }}</p>
                  <p class="crypto-ENname">{{ crypto.coin.code }}</p>
                </div>
              </td>
              <td>
                <span
                  v-if="priceData[crypto.coin.code + 'USDT']"
                  :class="{
                    'negative-change':
                      priceData[crypto.coin.code + 'USDT'].percentChange < 0,
                    'positive-change':
                      priceData[crypto.coin.code + 'USDT'].percentChange > 0,
                  }"
                >
                  
                  {{ Math.abs(priceData[crypto.coin.code + "USDT"].percentChange) }}
                  <span v-if="priceData[crypto.coin.code + 'USDT'].percentChange > 0">+</span>
                  <span v-if="priceData[crypto.coin.code + 'USDT'].percentChange < 0">-</span>
                </span>
              </td>
              <td class="crypto-prices">
                <span v-if="priceData[crypto.coin.code + 'USDT']">{{
                  parseFloat(priceData[crypto.coin.code + "USDT"].tomanBuying.toFixed(3)).toLocaleString()
                }}</span>
              </td>
              <td class="crypto-prices">
                <span v-if="priceData[crypto.coin.code + 'USDT']">{{
                  parseFloat(priceData[crypto.coin.code + "USDT"].tomanSelling.toFixed(3)).toLocaleString()
                }}</span>
              </td>
              <td>
                <TrendChart
                  :trend="trend[crypto.coin.code]"
                  :key="crypto.code + change"
                />
              </td>
            </template>
          </tr>
        </table>
      </div>
    </div>
    <div class="orders">
      <Orders :addOrder='add' :key="add"/>
    </div>
  </div>
</template>

<script>
import TrendChart from "../HomePage/TrendChart.vue";
import Sell from "../Modals/Sell.vue";
import Orders from "./Orders.vue";
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js'
export default {
  name: "Exchange",
  components: {
    Orders,
    Sell,
    TrendChart,
  },
  data() {
    return {
      cryptoList: this.value,
      priceData:'',
      markets_list: [{}],
      coinsDropdown: false,
      buy: true,
      sell: false,
      SellModal: false,
      BuyModal: true,
      coinNets: false,
      amountBuy: "",
      priceBuy: "",
      walletBuy: "",
      netIndex: null,
      selectedNet: "",
      selected: {},
      nets: {},
      chart: {},
      chartOb: {},
      change: 0,
      trend: [],
      totalPrice:1,
      credit:0,
      generalErr:"",
      ErrorText:{
        amount:''
      },
      add:true,
      information: [
        {
          wage: 321321321,
          minBuy: 0.7725,
          maxBuy: 5,
        },
      ],
      tr_heade: [
        {
          title: "رمز ارز",
        },
        {
          title: "تغییرات",
        },
        {
          title: "قیمت خرید از ما",
          subTitle: "تومان",
        },
        {
          title: "قیمت فروش به ما",
          subTitle: "تومان",
        },
        {
          title: "نمودار",
        },
      ],
      tradeData: {
        market_id: null,
        type: "",
        amount: 0,
        // address: "$2y$10$XkPl1cihsoWSG3fBi8DNS",
        // network_id: null,
      },
    };
  },
  props: ["value"],
  methods: {
    activeBuy() {
      if (!this.buy) {
        this.buy = true;
        this.sell = false;
        this.calcPrice()
      } else return true;
    },
    activeSell() {
      if (!this.sell) {
        this.sell = true;
        this.buy = false;
        this.calcPrice()
      } else return true;
    },
    async select(index) {
      this.selected = this.cryptoList[index].coin;
      this.selected_for_price = this.cryptoList[index].coin.code + "USDT";
      this.coinsDropdown = false;
      this.tradeData.market_id=this.cryptoList[index].id
      this.calcPrice()
      // this.netIndex = this.markets_list.data.data[index].coin.id;
      console.log(
        "selected",
        this.selected,
        this.priceData[this.selected_for_price]
      );
      // const net = await this.$axios.get(
      //   "http://adatether.6.vistadev.ir/api/markets/" +
      //     this.netIndex +
      //     "/networks"
      // );
      // this.nets = net.data.data;
      // console.log("price", this.selected_for_price);
    },
    async markets() {
      this.markets_list = await this.$axios.get(
        "/markets?status=ACTIVATED&size=500"
      );
      console.log("markets", this.markets_list.data.data);
    },
    async priceUSDT() {
      const res = await this.$axios.get(
        "/dollar-prices/last"
      );
      console.log("USDT", res);
      // this.state.price['USDT'].buying=res.data.data.price
    },
    selectNet(index) {
      this.selectedNet = this.nets[index].name;
      // console.log(this.nets[index].name)
    },
    showNets() {
      this.coinNets = !this.coinNets;
    },
    SellModalFun() {
      if (this.tradeData.amount && this.priceSell && this.walletSell) {
        this.SellModal = true;
      }
    },
    BuyModalFun() {
      if (this.amountBuy && this.priceBuy && this.walletBuy) {
        this.$emit("childToParentBuy", {
          modal: this.BuyModal,
          crypto: this.selected,
        });
      }
    },
    showCoins() {
      this.coinsDropdown = !this.coinsDropdown;
    },
    async chartData() {
      this.chart = await this.$axios.get(
        "/weekly-prices"
      );

      this.chart.data.data.forEach((element) => {
        this.chartOb[element.coin.code] = element.prices;
      });
      console.log("aaaaa=>>>", this.chartOb);
      for (let key in this.chartOb) {
        this.trend[key] = Object.values(this.chartOb[key]);
      }
      ++this.change;
      console.log("trend", this.trend);
      console.log("ob", this.chartOb);
    },
    calcPrice() {
      if(this.sell) {
        this.totalPrice=(this.priceData[this.selected_for_price].tomanSelling*this.tradeData.amount).toLocaleString()
      }
      else if(this.buy) {
        this.totalPrice=(this.priceData[this.selected_for_price].tomanBuying*this.tradeData.amount).toLocaleString()
      }
    },
    async personalinfo() {
           this.PersonalInformation = await this.$axios.get("/users/init",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                 this.credit=this.PersonalInformation.data.data.credits[0].credit
                 console.log('cccccccccccc',this.credit)
       },
    //  trade() {
    //   if( this.credit < this.totalPrice) {
    //    this.$emit('creditModal' , true)
    //   }
    //   else {
    //     this.tradeSend()
    //   }
      
    // },
       async trade() {
         this.state.loading = true
         
      if(this.$cookies.get('userStatus')==('OTP_DONE' || 'CONFIRMED__IDENTITY')) {
        this.state.authenErr = true
      }
      else {
        if(this.sell) {
        this.tradeData.type='SELL'
      }
      else if(this.buy) {
        this.tradeData.type='BUY'
      }
        try {
        const res = await this.$axios.post(
          "/orders",
          this.tradeData,
          { headers: { Authorization: `Bearer ${this.$cookies.get('token')}` } }
        )
        console.log(res)
        this.state.success = true
        this.add = !this.add
      }
      catch(err) {
        const res = err.response || err.toJSON();
        console.log(res.data.message);
        if(res.data.errors) {
        this.ErrorText.amount = res.data.errors.amount;
        }
        // this.ErrorText = res.data.errors.amount;
        // this.ErrorText = res.data.errors.amount;
        else if( res.data.status == 400){
          this.$emit('creditModal' , true)
        }
        else {
          this.generalErr = res.data.message
        }
          // console.log('ressssssss',res);
        
        // this.$emit('creditModal' , true)
      }
      }
      
      this.state.loading = false
      }
     
  },
  mounted() {
    this.chartData();
    this.markets();
    // this.cryptoList.filter(e => e.code !='TOMAN');
    console.log("value", this.value);
    console.log("ccrypyo", this.cryptoList);
    this.priceUSDT();
    console.log("priceData", this.priceData);
  this.personalinfo()
  this.priceData = this.state.price
    // console.log(this.cryptoList[0].code)
  },
  created() {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".coin-drop , .search-coins")) {
        this.coinsDropdown = false;
      }
      if (!event.target.closest(".nets-drop")) {
        this.coinNets = false;
      }
    });

//     Pusher

//     this.echo = new Echo({
//       broadcaster: 'pusher',
//       key: 'local',
//       wsHost: '185.110.189.39',
//       wsPort: 6001,
//       forceTLS: false,
//       cluster: 'mt1',
//       disableStats: true,
//     });
//  this.echo.channel(`realtime-price`)
//     .listen('RealTimePriceEvent', (e) => {
//       e.prices.forEach(element => {
//         this.priceData[element.market] = element
//        this.priceData=e;
//       });
//       this.priceData=e;
      // this.state.reload = !this.state.reload
    //   console.log('prices',this.state.price)
        
        // console.log('price in state',this.state.price.prices[1].market)
    // });
  },
  //   state.price.prices = {
  //       bitcoinusdt : {
  //           percent : '',
  //           buy : '',
  //           sell : '',
  //           market : 'bitcoinusdt'
  //       },
  //       bitcoinusdt1 : {
  //           percent : '',
  //           buy : '',
  //           sell : '',
  //           market : 'bitcoinusdt1'
  //       },
  //   }
};
</script>

<style scoped>
.exchange-title {
  font-weight: 500;
  font-size: 20px;
  color: #dbaba7;
}
.exchange-title > p {
  margin-bottom: 0;
}
.exchange {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.disable-btn {
  color:black
}
.exchange-inputs {
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
  /* width: 390px;
    min-width: 300px;
    margin: auto; */
  width: 50%;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.exchange-inputs-title {
  width: 213px;
  height: 40px;
  background: #2f3239;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buy-sell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(21, 22, 26, 0.8);
  border-radius: 5px;
  width: 203px;
  height: 35px;
}
.deActive-sell,
.deActive-buy {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  width: 50%;
  text-align: center;
}
.deActive-sell {
  color: rgba(255, 46, 33, 0.8);
}
.deActive-buy {
  color: rgba(15, 169, 2, 0.8);
}
.active-buy {
  width: 101px;
  height: 27px;
  background: #0fa902;
  border-radius: 5px;
  color: white;
  text-align: center;
  margin: 0 5px;
}
.active-sell {
  width: 101px;
  height: 27px;
  background: #ff2e21;
  border-radius: 5px;
  text-align: center;
  color: white;
  margin: 0 5px;
}
label {
  font-weight: normal;
  /* font-size: 16px; */
  color: white;
  height: 60px;
  font-size: clamp(12px, 1vw, 16px);
}
select,
input {
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  border: 0;
  height: 40px;
  color: rgba(255, 255, 255, 0.6);
}
.exchange-inputs-container {
  /* width: 390px; */
  height: 520px;
  background: #2f3239;
  background: #2f3239;
}
.buy-sell-form {
  width: 90%;
  margin: 0 auto;
  /* display: grid; */
  row-gap: 8px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 10px 0;
}
.buy-bit-btn {
  width: 100%;
  background: rgb(15, 169, 2);
  border-radius: 5px;
  height: 40px;
  border: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}
.buy-bit-btn:disabled {
  color: gray;
  background: rgba(15, 169, 2 , 0.5);
}
.sell-bit-btn {
  width: 100%;
  background: #ff2e21;
  border-radius: 5px;
  height: 40px;
  border: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  margin-top: 10px;
}
.form-text {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
  font-size: clamp(14px, 1vw, 16px);
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}
.form-text-amount {
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  direction: ltr;
}
.excahnge-table {
  display: flex;
  column-gap: 30px;
}
.title-p-box {
  color: rgba(255, 176, 7, 1);
  font-weight: 500;
  /* font-size: 16px; */
  font-size: clamp(14px, 1vw, 16px);
}
.title-sub-box {
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 156, 7, 0.6);
}
.first-td-crypto {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.box-image {
  width: 37px;
  height: 37px;
}
.crypto-name {
  color: #ffffff;
  font-weight: 500;
  /* font-size: 16px; */
  text-align: right;
}
.crypto-ENname {
  font-weight: 500;
  /* font-size: 16px; */
  color: rgba(255, 255, 255, 0.6);
}
.crypto-name,
.crypto-ENname {
  font-size: clamp(14px, 1vw, 16px);
}
.crypto-prices {
  color: #ffffff;
}
.negative-change {
  color: rgba(255, 46, 33, 1);
  font-weight: 500;
  /* font-size: 18px; */
  font-size: clamp(14px, 1vw, 18px);
  direction: ltr;
}
.positive-change {
  color: rgba(15, 169, 2, 1);
  font-weight: 500;
  /* font-size: 18px; */
}
.table-container {
  width: 100%;
  overflow: scroll;
  margin-top: 40px;
}
.table-container::-webkit-scrollbar {
  display: none;
}

.table-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  height: 520px;
}
table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  min-width: 800px;
}
tr:nth-child(odd) {
  background: #2f3239;
}
tr:nth-child(even) {
  background: #272a30;
}
.orders {
  margin-bottom: 40px;
}

.btn-dropdown {
  background: transparent;
  border: none;
}
.drop-row-ex:nth-child(even) {
  background-color: #1d1e23;
}
.drop-row-ex:nth-child(odd) {
  background-color: #151619;
}
.drop-row-ex img {
  width: 25px;
  height: 25px;
}
.drop-row-ex {
  display: flex;
  column-gap: 10px;
  width: 200px;
  height: 40px;
  align-items: center;
  border: none;
  padding: 0 10px;
  /* background-color: rgba(255, 255, 255, 0.04); */
}
.drop-name {
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.drop-ENname {
  color: rgba(255, 255, 255, 0.6);
}
.dropdown-title {
  color: #ffffff;
}
.search {
  background: transparent;
}
.drop-row-search {
  background: #2f3239;
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  border: none;
}

.coin-type-label {
  position: relative;
}
.coin-type-label > img {
  position: absolute;
  top: 30px;
  left: 10px;
  cursor: pointer;
}
.search-coins {
  height: 158px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 0 10px;
}
.search-coins::-webkit-scrollbar {
  display: none;
}
.myInput-container {
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
  padding: 0 10px;
}
#myInput {
  background-image: url("../../assets/search.png");
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 25px;
  font-size: 12px;
  position: sticky;
  top: 0;
  /* padding: 12px 20px 12px 40px; */
  /* border: 1px solid #ddd; */
  /* margin-bottom: 12px; */
  background-color: #2f3239;
  border-radius: 5px;
  color: white;
  padding-right: 20px;
  border: none;
  margin: 5px 0;
}

#myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

#myUL li a {
  border: 1px solid #ddd;
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block;
}
#myUL li img {
  width: 25px;
  height: 25px;
}
#myUL li a:hover:not(.header) {
  background-color: #eee;
}
.drop-row {
  display: flex;
  column-gap: 10px;
  width: 250px;
  height: 30px;
  align-items: center;
  border: none;
  margin: 5px 0;
  /* padding: 0 10px; */
  /* margin-right: 20px; */
}

.selected-crypto {
  height: 40px;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  column-gap: 5px;
}

.v-dropdown-caller {
  background: rgba(255, 255, 255, 0.04) !important;
  justify-content: flex-end !important;
  height: 40px;
}
@media only screen and (max-width: 1000px) {
  .excahnge-table {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
</style>
