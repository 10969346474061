<template>
    <div class="login">
                    <div class="login-form-title">ثبت نام</div>
                    <div>
                        <hr>
                    </div>
                        <form action="" @submit.prevent="send">
                            <label for="">
                                ایمیل
                                <input type="text" v-model="data.email">
                                <p class="error-p" v-if="emailError">
                                     <!-- <img class="error-img" src="@/assets/error.png" alt=""> -->
                                    {{emailError}}
                                </p>
                            </label>
                            <label for="">
                                <button class="show-pass" @click="showPassword" >
                                    <img class="show-pass-image" src="@/assets/showpass.png" >
                                </button>
                                رمزعبور
                                <input class="password" :type="type" v-model="data.password">
                                <p class="error-p" v-if="passError">
                                    <!-- <img class="error-img" src="@/assets/error.png" alt=""> -->
                                    {{passError}}
                                </p>
                            </label>
                            <label for="">
                                کد دعوت(اختیاری)
                                <input  type="text" v-model="data.code">
                            </label>
                            <div class="checkinput">
                                <div id="input"><input type="checkbox" class="policy-checkbox" v-model="data.rule"></div>
                                    <label for="policy" class="checkbox-text">
                                        <a href="" class="policy-link"> قوانین و شرایط </a>
                                        سایت را مطالعه نموده و میپذیرم
                                    </label>
                            </div>
                                <vue-recaptcha sitekey="Your key here"></vue-recaptcha>
                            <div>
                                <button class="subscribe-btn" type="submit" :disabled="!data.email || !data.password || !data.rule">ثبت نام</button>
                                <p class="sub-link">حساب کاربری دارید؟
                                    <a class="link-p" href="/login" @click.prevent="$router.push('/login')"> وارد شوید </a>
                                </p>
                            </div>
                            </form>
                                <button class="google-btn">
                                    <img src="@/assets/google.png" alt="">
                                    ورود با حساب گوگل
                                </button>
                </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: 'HomePage',
  components :{
    VueRecaptcha
    
  },
  data() {
      return {
          type: 'password',
        btnText: 'Show Password',
        emailError:"",
        passError:"",
        data: {
            email:"",
            password:"",
            code:"",
            rule:false,
            osType: '',
            deviceType: '',
            browser: '',
        }
      }
  },
  methods: {
      showPassword(e) {
          if(this.type === 'password') {
				this.type = 'text'
				this.btnText = 'Hide Password'
			} else {
				this.type = 'password'
				this.btnText = 'Show Password'
			}
            e.preventDefault();
      },
      async send() {
                await this.checkEmail()
                await this.check2Pass()
                this.state.loading = true
               try{
                 const res =await this.$axios.post('/signup', this.data)
                 console.log(res.data.status)
                 if(res.data.type=='success') {
                     this.$router.push('/register-email')
                     this.state.email=this.data.email
                 }
                } 
                catch(err) {
                    const res = err.response || err.toJSON()
                    if(res.data.errors) {
                        this.emailError = res.data.errors.email[0]
                    }
                    
                    // this.emailError=res.data.message
                }
                this.state.loading = false
            },
            async check2Pass() {
                if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                    this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                    // this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                    console.log(this.passError)
                    // return Promise.reject('wrong pass : ' + this.data.password)
                } else {
                    this.passError = ''
                }
            },
            async checkEmail() {
                if (!this.$validEmail(this.data.email) || !this.data.email) {
                    this.emailError = 'فرمت پست الکترونیکی معتبر نیست'
                    console.log(this.emailError)
                    // this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                    // return Promise.reject('repeat failed : ' + this.data.email)
                } else {
                    this.emailError = ''
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
                console.log(this.data.osType , this.data.browser ,  this.data.deviceType)
            },
  },
  mounted() {
      this.getData()
            // if (this.$route.query.ref || localStorage.referralCode) {
            //     localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
            // }
  },
  }
</script>

<style scoped>
.login , form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
    .login-form-title {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
    }
    hr {
        border: 0;
        height: 1px;
        background-image: linear-gradient(90deg , black , white , black);
    }
    label , input {
        width: 100%;
        /* background: #212225; */
        border-radius: 5px;
        border: 0;
    }
    label {
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
        position: relative;
    }
    input {
        height: 40px;
        color: rgba(255, 255, 255, 0.7);
        background: rgba(255, 255, 255, 0.04);
    }
    .subscribe-btn {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border: 0;
        font-weight: 500;
font-size: 16px;
    }
    .sub-link {
        font-weight: normal;
font-size: 14px;
color: #FFFFFF;

    }
    .link-p {
        text-decoration-line: underline;
        color: #53ACFF;
    }
    .google-btn {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        background: transparent;
        color: #FFFFFF;
        height: 50px;
    }
    .forget-a {
        margin: 0;
        text-align: left;
    }
    .forget-a > a {
        text-decoration-line: underline;
        color: #FFFFFF;
        font-weight: normal;
        font-size: 12px;
    }
    
    .checkinput {
        display: flex;
    }
    .policy-checkbox {
        width: 16px;
        height: 16px;
    }
    .checkbox-text {
        font-weight: normal;
        font-size: 14px;
    }
    .policy-link {
        color: #53ACFF;

    }
    .show-pass {
        border: 0;
        background: transparent;
        position: absolute;
        left: 5px;
        top: 35px;
        cursor: pointer;
    }
</style>
<style >
    .grecaptcha-badge {
        position: inherit !important;
    }
    .error-p {
        color:#FF2E21;
        font-size: 12px;
    }
    .error-img {
        width: 20px;
        height: 20px;
    }
</style>