<template>
  <transition>
    <div class="modal-backdrop">
      <div class="modal">
        <img
          @click="closeModale"
          class="close-icon"
          src="@/assets/close-modal.png"
          alt=""
        />
        <span class="modal-title">
          شناسایی دو عاملی
        </span>
        <div class="input-container">
          <span class="describe">
            کد ارسال شده به شماره {{ }} را وارد کنید
          </span>
          <CodeInput :loading="false" v-on:complete="onComplete" />
        </div>
        <button
          :disabled="disablebtn"
          :class="{
            'deActive-btn': this.disablebtn,
            'active-btn': !this.disablebtn,
          }"
        >
          ثبت
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
  name: "SMSTwoStep",
  components: {
    CodeInput,
  },
  data() {
    return {
      close: false,
      disablebtn: true,
    };
  },
  methods: {
    closeModale() {
      this.$emit("closeModal", this.close);
    },
    onComplete() {
      this.disablebtn = false;
    },
  },
  created: function() {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".modal , .checkbox")) {
        this.$emit("closeModal", this.close);
      }
      // else if(event.target.closest('.modal , .add-btn')) {
      // }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.modal {
  display: flex;
  flex-grow: 1;
  max-width: 370px;
  min-height: 286px;
  background: rgba(21, 22, 26, 1);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.close-icon {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 16px;
}
.modal-title {
  font-weight: bold;
  font-size: 24px;
  color: #fefefe;
}
.describe {
  font-size: 12px;
  color: #fefefe;
}
.deActive-btn,
.active-btn {
  border-radius: 6px;
  border: none;
  color: #fcfcfc;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  height: 44px;
}
.active-btn {
  background: linear-gradient(
    94.21deg,
    #ffb904 0%,
    #e89900 47.18%,
    #f48400 99.06%
  );
}
.deActive-btn {
  background: linear-gradient(
    94.21deg,
    rgba(255, 245, 4, 0.5) 0%,
    rgba(255, 199, 0, 0.5) 47.18%,
    rgba(244, 132, 0, 0.5) 99.06%
  );
}
</style>
