<template>
    <div class="notification">
        <table>
            <!-- <tr>
                <td>
                    <div>
                    <img  src="@/assets/dote-yellow.png" alt="">
                    <span> واریز ریالی با موفقیت انجام شد.</span>
                    </div>
                    
                </td>
                <img  class="notif-img" src="@/assets/notif-icon.png" alt="">
            </tr>
            <tr>
                <td>
                    <div>
                    <img src="@/assets/dote-yellow.png" alt="">
                    <span> واریز ریالی با موفقیت انجام شد.</span>
                    </div>
                   
                </td>
                 <img class="notif-img" src="@/assets/notif-icon.png" alt="">
            </tr>
            <tr>
                <td>
                    <div>
                    <img src="@/assets/dote-yellow.png" alt="">
                    <span> واریز ریالی با موفقیت انجام شد.</span>
                    </div>
                   
                </td>
                 <img class="notif-img" src="@/assets/notif-icon.png" alt="">
            </tr>
            <tr>
                <td>
                    <div>
                    <img src="@/assets/dote-yellow.png" alt="">
                    <span> واریز ریالی با موفقیت انجام شد.</span>
                    </div>
                    
                </td>
                <img class="notif-img" src="@/assets/notif-icon.png" alt="">
            </tr> -->
            <tr  v-for="(Announcement , index) in AnnouncementList" :key="index">
                <a href="/notification" @click.prevent="reade(index)" >
                    <td :class="{'read' : Announcement.is_read===true}"> 
                        <img src="@/assets/dote-yellow.png" alt="">
                        {{Announcement.title}}
                    </td>
                </a>
            </tr>
            <tr class="tr-link">
                <a class="more" href="/notification" @click.prevent="$router.push('/notification')">
                مشاهده بیشتر
                </a>
                
            </tr>
        </table>
        
    </div>
</template>

<script>

export default {
  name: 'NotificationBox',
  components :{
    
  },
  data() {
      return {
          AnnouncementList:[]
      }
  },
  methods: {
      async notification() {
          this.state.loading = true
          const res = await this.$axios.get(
              '/notifications',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
            this.AnnouncementList = res.data.data
            this.state.loading = false
      },
      async reade(index) {
          await this.$axios.patch(
              `/notifications/${this.AnnouncementList[index].id}`,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          this.$router.push('/notification')
      }
  },
  mounted() {
      this.notification()
  }
  }
</script>

<style lang="scss" scoped>
.notification {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    max-width: 352px;
    max-height: 352px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.notification::-webkit-scrollbar {
    display: none;
}
table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
        width: 352px;
    }
    tr:nth-child(even) {
        background: #2F3239
    }
    tr:nth-child(odd) {
        background: #272A30
    }
    tr {
        color: #ffff;
        height: 40px;
        display: flex;
    align-items: center;
    justify-content: space-between;
        a {
            color: white;
        }
    }
    td {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 0 20px;
    justify-content: space-between;
    }
    tr p {
        margin: 0;
    }
    .tr-link {
        font-weight: normal;
        font-size: 13px;
        color: rgba(219, 171, 167, 1);
    }
    .more {
        text-decoration: underline !important;
        font-weight: normal;
        font-size: 13px;
        color: rgba(219, 171, 167, 1);
        text-align: center !important;
        width: 100%;
    }
    .notif-img {
        padding-left: 20px;
    }
    @media only screen and (max-width:400px) {
        .notification , table{
            width: auto;

        }
    }
</style>
