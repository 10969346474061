var items = [
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    },
    {
        "title":"تحولات اخیر بازار بیتکوین",
        "discribe":"با توییتر ایلان ماسک بازار ارزهای دیجیتال به قهقرا رفت..."
    }
]
export default items