<template>
    <div class="about-body">
        <!-- <NavbarHome/> -->
        <div class="container">
            <div>
                <p class="about-title">درباره آداتتر</p>
            </div>
            <div class="about-container">
                <p class="about-sub-title">آداتتر چه میکند؟</p>
                <p class="about-text" v-html="about"/>
                <div class="about-image-container">
                    <img class="about-image" src="@/assets/aboutus.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import NavbarHome from "./NavbarHome.vue"
export default {
  name: 'HomePage',
  components :{
    // NavbarHome
  },
  data() {
      return {
          about:""
      }
  },
  methods: {
      async getAbout() {
                this.loading = true
                this.about = (await this.$axios.get('/pages?name=ABOUT_US')).data.data.content

                this.loading = false
                // console.log(this.about.data.data[0])
            }
  },
  mounted() {
      this.getAbout()
  }
  }
</script>

<style lang="scss" scoped>
.about-body {
    background: #2F3239;
    width: 100%;
    min-height: calc(100vh - 60px);
    background-image: url('../../assets/faq-background.png');
    overflow: auto;
    margin-top: -80px;
}
    .about-container {
        border-radius: 5px;
        background:rgba(21, 22, 26, 0.7);
        padding:10px 40px;
        min-height: 556px;
    }
    .about-title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
        margin: 30px 0;
    }
    .about-sub-title {
        font-weight: 500;
        font-size: 16px;
        color: #DBABA7;
    }
    .about-text {
        font-weight: normal;
        font-size: clamp(14px , 2vw , 16px);
        color: #FFFFFF;
    }
    .about-image-container {
        text-align: left;
    }
    .about-image {
        max-width: 100%;
    }
</style>
