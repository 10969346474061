<template>
    <div class="history">
        <p class="history-title">
            تاریخچه
        </p>
        <div class="orders-history">
            <div class="orders-history-title">
                تاریخچه سفارشات
            </div>
            <div class="drop-down-container">
                <div class="dropdown">
                    <div class="dropdown_title">
                        <span v-if="selectedType != ''">
                            {{selectedType}}
                        </span>
                        <span v-else>
                            نوع سفارش
                        </span>
                        <img class="open_drop" src="@/assets/caretdown.png" alt="" @click="typesItems = !typesItems">
                    </div>
                    <transition name="fade">
                        <div class="dropdown_list" v-if="typesItems">
                            <div v-for="(type , index) in types" :key="index" @click="selectType(index)">
                                {{type.title}}
                            </div>
                        </div>
                    </transition>
                </div>
                <div class="dropdown">
                    <div class="dropdown_title">
                        <span v-if="orderDateSelect != ''">
                            {{orderDateSelect}}
                        </span>
                        <span v-else>
                            بازه زمانی
                        </span>
                        <img class="open_drop" src="@/assets/caretdown.png" alt="" @click="showOrdersDate = !showOrdersDate">
                    </div>
                    <transition name="fade">
                        <div class="dropdown_list" v-if="showOrdersDate">
                            <div v-for="(type , index) in ordersDate" :key="index" @click="selectDateOrder(index)">
                                {{type.title}}
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

            <div class="table-container-orders">
                <table class="orders">
                    <tr class="tr-head-orders">
                        <td>
                            شماره سفارش
                        </td>
                        <td>
                            نوع سفارش
                        </td>
                        <td>
                            رمز ارز
                        </td>
                        <td>
                            مقدار
                        </td>
                        <td>
                            قیمت واحد <span class="tooman"> تومان </span>
                        </td>
                        <td>
                            قیمت کل <span class="tooman"> تومان </span>
                        </td>
                        <td>
                            زمان
                        </td>
                        <td>
                            پیگیری
                        </td>
                        <td>
                            وضعیت
                        </td>
                    </tr>
                <tbody>
                <template v-for="(OrdersHistory , index) in orders" >
                <tr :key="index" class="tr-body-orders" v-if="OrdersHistory != 'PENDING'">
                    <td>
                        {{OrdersHistory.id}} 
                    </td>
                    <td :class="{'td-buy' : OrdersHistory.type==='BUY' , 'td-sell':OrdersHistory.type==='SELL'}">
                        <span v-if="OrdersHistory.type==='BUY'">
                            خرید
                        </span>
                        <span v-if="OrdersHistory.type==='SELL'">
                            فروش
                        </span>
                    </td>
                    <td>
                        {{OrdersHistory.market.coin.label}}
                    </td>
                    <td>
                        {{OrdersHistory.amount}}
                    </td>
                    <td>
                        {{parseFloat(OrdersHistory.price).toLocaleString()}}
                    </td>
                    <td>
                        {{OrdersHistory.total.toLocaleString()}}
                    </td>
                    <td>
                        {{$G2J(OrdersHistory.created_at)}} 
                    </td>
                    <td>
                        {{OrdersHistory.order}}-
                    </td>
                    <td>
                        <img v-if="OrdersHistory.status=='ACCEPTED'" src="@/assets/status-done.png" alt="">
                        <img v-else src="@/assets/status-fail.png" alt="">
                    </td>
                </tr>
                </template>
                </tbody>
                </table>
            </div>
            
        </div>
        <div class="exchange-history">
        <div class="orders-history-title">
                تاریخچه تراکنش ها
            </div>
            <div class="drop-down-container">
                <div class="dropdown">
                
                    <div class="dropdown_title">
                        <span v-if="selectedtransaction != ''">
                            {{selectedtransaction}}
                        </span>
                        <span v-else>
                            نوع تراکنش
                        </span>
                        <img class="open_drop" src="@/assets/caretdown.png" alt="" @click="transactionItems = !transactionItems">
                    </div>
                    <transition name="fade">
                        <div class="dropdown_list" v-if="transactionItems">
                            <div v-for="(type , index) in transaction" :key="index" @click="selecttransaction(index)">
                                {{type.title}}
                            </div>
                        </div>
                    </transition>
                
                </div>
                <div class="drop-down-title">
                    <div class="dropdown">
                        <div class="dropdown_title">
                            <span v-if="selectTransactionDate != ''">
                                {{selectTransactionDate}}
                            </span>
                            <span v-else>
                                بازه زمانی
                            </span>
                            <img class="open_drop" src="@/assets/caretdown.png" alt="" @click="showTransactionDate = !showTransactionDate">
                        </div>
                        <transition name="fade">
                            <div class="dropdown_list" v-if="showTransactionDate">
                                <div v-for="(type , index) in transactionDate" :key="index" @click="transactionDateFun(index)">
                                    {{type.title}}
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                </div>
                
            
            <div class="table-container-exchange">
                <table class="exchange">
                    <tr class="tr-head-orders">
                        <td>
                            شماره پیگیری
                        </td>
                        <td>
                            نوع تراکنش
                        </td>
                        <td>
                            شماره حساب / آدرس کیف پول
                        </td>
                        <td>
                             مبلغ <span class="tooman"> تومان </span>
                        </td>
                        <td>
                            زمان
                        </td>
                        <td>
                            وضعیت
                        </td>
                        <td>
                            تکمیل تراکنش
                        </td>
                    </tr>
                    <tr v-for="(exchangeHistory , index) in exchange" :key="index" class="tr-body-orders">
                        <td>
                            {{exchangeHistory.id}}
                        </td>
                        <td :class="{'td-buy' : exchangeHistory.type==='DEPOSIT' , 'td-sell':exchangeHistory.type==='WITHDRAW'}">
                           <span v-if="exchangeHistory.type==='DEPOSIT'">
                               واریز
                           </span>
                           <span v-if="exchangeHistory.type==='WITHDRAW'">
                               برداشت
                           </span>
                        </td>
                        <td>
                            <span v-if="exchangeHistory.payment">
                                {{exchangeHistory.payment.address}}
                            </span>
                            <span v-else>
                                {{exchangeHistory.account.account_number}}
                            </span>
                        </td>
                        <td>
                             {{exchangeHistory.amount.toLocaleString()}}
                        </td>
                        <td>
                            {{$G2J(exchangeHistory.created_at)}}
                        </td>
                        <td>
                            <img v-if="exchangeHistory.status=='ACCEPTED'" src="@/assets/status-done.png" alt="">
                            <img v-else src="@/assets/status-fail.png" alt="">
                        </td>
                        <td >
                            <button 
                            class="complete_btn"
                            @click="complete(index)" v-if="exchangeHistory.status=='PENDING' && exchangeHistory.type=='DEPOSIT'">
                            ادامه 
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
</div>
    <!-- </div> -->
</template>

<script>
// import OrdersHistory from "../../library/OrdersHistory.js"
// import exchangeHistory from "../../library/exchangeHistory.js"
import crypto from "../../library/crypto.js"
// import cryptoType from '../DropDowns/CryptoType.vue'
// import Dropdown from 'v-dropdown'
export default {
  name: 'History',
  components :{
    // cryptoType,
    // 'v-dropdown': Dropdown
  },
   data() {
      return {
            orders:[],
            exchange:[],
            cryptoList:crypto,
            coinsDropdown:false,
            typesItems:false,
            selectedType : "",
            types :[
                {
                    title: "همه",
                    filter : ""
                },
                {
                    title: "خرید",
                    filter : "BUY"
                },
                {
                    title: "فروش",
                    filter : "SELL"
                }
            ],
            showOrdersDate:false,
            orderDateSelect:"",
            ordersDate : [
                {
                    title : "همه",
                    filter : ""
                },
                {
                    title : "روزانه",
                    filter : ""
                },
                {
                    title : "هفتگی",
                    filter : ""
                }
            ],
            transactionItems:false,
            selectedtransaction : "",
            transaction :[
                {
                    title: "همه",
                    filter : ""
                },
                {
                    title: "واریز",
                    filter : "DEPOSIT"
                },
                {
                    title: "برداشت",
                    filter : "WITHDRAW"
                }
            ],
            showTransactionDate : false,
            selectTransactionDate:"",
            transactionDate:[
                {
                    title : "همه",
                    filter: ""
                },
                {
                    title : "روزانه",
                    filter: ""
                },
                {
                    title : "هفتگی",
                    filter: ""
                }
            ],
            times : [
                {
                    title : 'همه'
                },
                {
                    title : 'روزانه'
                },
                {
                    title : 'هفتگی'
                },
                {
                    title : 'ماهانه'
                }
            ], filter: {
                type:'',
                created_atFrom:''
                ,created_atTo:''
            }, 
            currentPage : 1,
            totalPage : 0
      }
  },
  methods : {
      myFunction() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value;
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByClassName("drop-name")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
    
},
async getOrders() {
    this.state.loading = true
        const res = await this.$axios.get(
            '/orders',
                {
                    headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`},
                    queryParams:{}
                    
                    }
        )
        this.orders=res.data.data
        console.log(this.orders)
    },
    async getTransaction() {
        this.state.loading = true
        const res = await this.$axios.get(
            '/transactions',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
        )
        this.exchange=res.data.data
        console.log(this.exchange)
        this.state.loading = false
    },
    complete(index) {
        console.log('index',this.exchange[index])
        this.$emit('continue',this.exchange[index].id)
    },
    showCoins() {
        this.coinsDropdown=!this.coinsDropdown
    },
    async selectType(index) {
        this.state.loading = true
        this.selectedType = this.types[index].title
        // this.selectedType_filter = this.types[index].filter
        console.log('test',this.types[index].filter);
        const res = await this.$axios.get(
            '/orders',
                    {
                    headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`},
                    params:{type:this.types[index].filter}
                    }
        )
        this.orders=res.data.data
        this.state.loading = false
    },
    async selectDateOrder(index) {
        this.state.loading = true
        this.showOrdersDate = false
        this.orderDateSelect = this.ordersDate[index].title
        const res = await this.$axios.get(
            '/transactions',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`},
                params:{type:this.ordersDate[index].filter}}
        )
        this.exchange=res.data.data
        this.state.loading = false
    },
    async transactionDateFun(index) {
        this.state.loading = true
        this.showTransactionDate = false
        this.selectTransactionDate = this.transactionDate[index].title
        // this.selectedType_filter = this.types[index].filter
        console.log('test',this.transactionDate[index].filter);
        const res = await this.$axios.get(
            '/orders',
                    {
                    headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`},
                    params:{type:this.transactionDate[index].filter}
                    }
        )
        this.orders=res.data.data
        this.state.loading = false
    },
    async selectDate() {
        // this.state.loading = true
        // this.selectedType = this.types[index].title
        // // this.selectedType_filter = this.types[index].filter
        // console.log('test',this.types[index].filter);
        // const res = await this.$axios.get(
        //     '/orders',
        //             {
        //             headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`},
        //             params:{type:this.types[index].filter}
        //             }
        // )
        // this.orders=res.data.data
        // this.state.loading = false
    },
    async selecttransaction(index) {
        this.state.loading = true
        this.transactionItems = false
        this.selectedtransaction = this.transaction[index].title
        const res = await this.$axios.get(
            '/transactions',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`},
                params:{type:this.transaction[index].filter}}
        )
        this.exchange=res.data.data
        this.state.loading = false
    }

  },
  created() {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".open_drop")) {
        this.typesItems = false;
        this.showOrdersDate = false;
        this.transactionItems = false
        this.showTransactionDate = false
      }
    });
  },
  mounted() {
      this.getOrders()
      this.getTransaction()

  }
  }
</script>

<style scoped>
    .complete_btn {
        border: 1px solid #FF9C07;
        border-radius: 5px;
        width: 50px;
        color: #FF9C07;
        background: transparent;
    }
    .history {
        width: 95%;
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .history-title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
    }
    .dropdown {
        background:rgba(21, 22, 26, 0.3);
        border-radius: 5px;
        width: 150px;
        height: 35px;
        font-size: 16px;
        color: #FFFFFF;
        position: relative;
    }
    .dropdown_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        font-size: 16px;
    }
    .dropdown_list {
        position: absolute;
        height: 90px;
        width: 100%;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
        background: rgba(21, 22, 26, 1);
        border-radius: 5px;
        margin-top: 5px;
    }
     .dropdown_list > div {
         cursor: pointer;
          padding: 0 5px;
        height: 30px;
     }
    .dropdown_list > div:nth-child(odd){
        background: rgba(21, 22, 26, 1);
       
    }
    .dropdown_list > div:nth-child(even){
        background: rgba(47, 50, 57, 0.3);
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s ease-out;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .orders-history-title {
        font-weight: 500;
font-size: 18px;
color: #DBABA7;
    }
    .table-container-orders , .table-container-exchange {
        overflow: scroll;
        -ms-overflow-style: none;  
  scrollbar-width: none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
border-radius: 5px;
    }
    .table-container-orders::-webkit-scrollbar , .table-container-exchange::-webkit-scrollbar {
  display: none;
}
    .orders , .exchange {
        width: 100%;
      text-align: center;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
border-radius: 5px;
height: auto;
border-collapse: collapse;
min-width: 700px;

    }
    tr:nth-child(odd) {
      background: #2F3239;
  }
  tr:nth-child(even) {
      background: #272A30;
  }
  .td-buy {
     
        color: #0FA902;

  }
  .td-sell {
 color: #FF2E21;
  }
  .tr-body-orders {
       height: 40px;
      font-weight: normal;
/* font-size: 16px; */
color: #FFFFFF;
font-size: clamp(12px , 1.5vw , 16px);
  }
  .tr-head-orders {
      height: 60px;
       font-style: normal;
        font-weight: 500;
        color: rgba(255, 176, 7, 1);
  }
  .drop-down-title {
      width: 150px;
        height: 35px;
        border-radius: 5px;
        /* background-color:rgba(21, 22, 26, 0.3); */
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
  }
  .drop-img {
      width: 24px;
      height: 24px;
  }
  .drop-down-title > img   {
      margin-left: 10px;
      width: 2vw;
  }
  .drop-down-title > p {
      margin-right: 10px;
  }
  .drop-down-title > p {
       font-weight: normal;
        /* font-size: 16px; */
        font-size: clamp(10px , 1.5vw ,16px);
        color: #FFFFFF;
  }
  .drop-down-container {
      display: flex;
      /* column-gap: 30px; */
      column-gap: 3vw;
  }
  .orders-history , .exchange-history {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
  }
  .exchange-history {
      margin-bottom: 40px;
  }
  .tooman {
      font-weight: normal;
        font-size: 14px;
        color: rgba(255, 176, 7, 0.6);
  }
  select {
        background:url('../../assets/caretdown.png')no-repeat ;
        background-color: rgba(21, 22, 26, 0.3);
border-radius: 5px;
width: 150px;
height: 30px;
color: #FFFFFF;
border: none;
text-align: center;

    }
    select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}
    option {
        text-align: center;
        border: none;
    }
    
    option:nth-child(odd) {
        
         background: #1D1F23;
    }
    option:nth-child(even) {
       background: #22252A;
    }
    .btn-dropdown {
        background: transparent;
        border: none;
    }
    .drop-row:nth-child(even) {
        background-color: #151619;
    }
    .drop-row:nth-child(odd) {
        background-color: #1D1E23;
    }
    .drop-row img {
        width: 25px;
        height: 25px;
        
    }
    .drop-row {
        display: flex;
    column-gap: 10px;
    width: 200px;
    height: 30px;
    align-items: center;
    border: none;
    /* padding: 0 10px; */
    margin-right: 20px;
    }
    .drop-name {
        font-weight: normal;
font-size: 14px;
color: #FFFFFF;
    }
    .drop-ENname {
        color: rgba(255, 255, 255, 0.6);
    }
    .dropdown-title {
        color: #FFFFFF;

    }
    .drop-row-search {
        background: #2F3239;
/* border-radius: 5px; */
display: flex;
align-items: center;
border: none;
    }
    .search {
        width: 90%;
        background: #2F3239;
        border: none;
        color: #FFFFFF;
        height: 30px;
    }


.search-coins {
    width: 200px;
    height: 158px;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
border-radius: 5px;
position: absolute;
    top: 35px;
    right: -25px;
}
.search-coins::-webkit-scrollbar {
  display: none;
}
.myInput-container {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
}
#myInput {
    background-image: url('../../assets/search.png');
  background-position:right;
  background-repeat: no-repeat;
  width: 187px;
height: 25px;
  font-size: 12px;
  position: sticky;
  top: 0;
  /* padding: 12px 20px 12px 40px; */
  /* border: 1px solid #ddd; */
  /* margin-bottom: 12px; */
  background-color: #2F3239;
border-radius: 5px;
color: white;
padding-right: 20px;
border: none;
margin: 5px 0;
}

#myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#myUL li a {
  border: 1px solid #ddd;
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block
}

#myUL li a:hover:not(.header) {
  background-color: #eee;
}



    @media only screen and (max-width:800px) {
        .drop-down-container {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 10px;
        }
    }
</style>
<style >
    .v-dropdown-caller {
        width: 100% ;
    display: flex !important;
    justify-content: space-around;
    background-color: rgba(21, 22, 26, 0.3);
    }
    .v-dropdown-container {
        border-radius: 5px;
        border: none !important;
    }
</style>