<template>
    <transition name="modal-fade" >
        <div class="modal-backdrop" >
          <div class="modal" :key="test">
            <!-- <img @click="closeModale" class="close-icon" src="@/assets/close-icon.svg" alt=""> -->
            <span class="message-title">
              <!-- واریز {{selected}} -->
            </span>
            <div class="exchange-inputs-container">
          <div class="buy-sell-form">
            <label for="" class="coin-type-label"
              >نوع ارز
              <div class="selected-crypto">
                 <img class="selected-img" :src=cryptoImages[selectedCoin[selectedCoinWallet.id].coin.code].img alt="" />
                {{selectedCoin[selectedCoinWallet.id].coin.label}}
               
                <!-- <span>{{ selected }}</span> -->
                <!-- <span>{{ selected.code }}</span> -->
              </div>
              <!-- <img
                class="drop-img coin-drop"
                src="@/assets/caretdown.png"
                alt=""
                @click="showCoins"
              /> -->
              <!-- <div class="search-coins" v-if="coinsDropdown">
                <div class="myInput-container">
                  <input type="text" id="myInput" title="Type in a name" />
                </div>
                <ul id="myUL">
                  <li>
                    <div
                      class="drop-row"
                      v-for="(crypto, index) in cryptoList"
                      :key="index"
                      @click="select(index)"
                    >
                      <img :src="cryptoImages[crypto.coin.code].img" alt="" />
                      <span class="drop-name">{{ crypto.label }}</span>
                      <span class="drop-ENname">{{ crypto.code }}</span>
                    </div>
                  </li>
                </ul>
              </div> -->
              
            </label>
            <label for="" class="coin-type-label"
              > شبکه
              <div class="selected-crypto">
                <!-- <img v-if="selected.length!=0" :src=cryptoImages[selected.code].img alt="" /> -->
                <span v-if="selectedNets != ''">{{ selectedNets }}</span>
                <span v-else>{{nets[0].network}}</span>
                <!-- <span>{{ selected.code }}</span> -->
              </div>
              <img
                class="drop-img coin-drop"
                src="@/assets/caretdown.png"
                alt=""
                @click="showCoins"
              />

              <div class="search-coins" v-if="coinsDropdown">
                <!-- <div class="myInput-container">
                  <input type="text" id="myInput" title="Type in a name" />
                </div> -->
                <!-- <ul id="myUL" :key="test">
                  <li>-->
                    <div
                      class="drop-row"
                      v-for="(crypto, index) in nets"
                      :key="index"
                      @click="select(index)"
                    > 
                      <!-- <img :src="cryptoImages[crypto.coin.code].img" alt="" /> -->
                      <span class="drop-name" @click="seletctNet(index)">{{ crypto.network }}</span>
                      <!-- <span class="drop-ENname">{{ crypto.code }}</span> -->
                    <!-- </div> -->
                  <!-- </li>
                </ul> -->
                    </div>
              </div>
              <!-- <p class="error-p" >
                {{errorText}}
              </p> -->
            </label>
            <p class="error-p">
              {{crypto_network_idErr}}
            </p>
            <label for="">
              مقدار مورد نظر
              <input type="text" v-model="tradeData.amount" @input="calcPrice"/>
              <p class="error-p">
                {{amountErr}}
              </p>
            </label>
            <label for="">
              مبلغ کل معامله
              <input type="text" v-model="totalPrice" disabled/>
            </label>
            <label for="">
                آدرس کیف پول
                <input type="text" v-model="withdrawal_data.address ">
                <p class="error-p">
                  {{addressErr}}
                </p>
            </label>
            <label for="">
              کد شناسایی دو عاملی
              <input type="text" withdrawal_data.code>
            </label>
            <label for="" v-if="selectedNets.memo!='' || nets[0].memo!=''">
              کد ممو
              <input type="text" v-model="withdrawal_data.memo">
            </label>
            <!-- <p class="form-text">
              کارمزد انتقال:
              <span class="form-text-amount">
                  {{selectedCoin[selectedCoinWallet.id]..toLocaleString() }} تومان
              </span>
            </p> -->
            <p class="form-text">
              حداقل تعداد خرید :{{ selectedCoin[selectedCoinWallet.id].min_amount.toLocaleString() }}
              <span class="form-text-amount">
                <!-- {{ selected.min_amount }} -->
                <!-- <span>{{ selected.coin.code }}</span>-->
                </span> 
            </p>
            <p class="form-text">
              حداکثر تعداد خرید  :{{ selectedCoin[selectedCoinWallet.id].max_amount.toLocaleString() }}
              <span class="form-text-amount">
                <!-- {{  selected.min_amount }} {{ selected.label }} -->
              </span>
            </p>
          </div>
        </div>
        <p class="error-p" >
                {{errorText}}
              </p>
            <div class="btns">
              
                <button class="deposit-btn" @click="depositReq">
                    برداشت {{selectedCoin[selectedCoinWallet.id].coin.label}}
                </button>
            </div>
          </div>
        </div>
    </transition>
</template>

<script>

export default {
  name: 'WithdrawalModal',
  components :{
    
  },
  props : [
      'value' , 'selectedCoinWallet'
  ],
  data() {
      return {
         close:false,
         test:1,
         cryptoList: this.value,
         coinsDropdown: false,
         priceData: this.state.price,
         selected: {},
         totalPrice:1,
         selectedNets:"",
         depositData:{},
         netId:null,
         errorText:'',
         transactionId:null,
         addressErr :"",
         amountErr : "",
         crypto_network_idErr:"",
         withdrawal_data:{
             payment_method:"",
             amount:"",
             address:"",
             memo:"",
             crypto_network_id:this.netId,
             coin_id:"",
             code:"",
         },
         tradeData: {
            market_id: null,
            type: "",
            amount: 0,
            // address: "$2y$10$XkPl1cihsoWSG3fBi8DNS",
            // network_id: null,
        },
        nets : [],
        information: [
                {
                wage: 321321321,
                minBuy: 0.7725,
                maxBuy: 5,
                },
            ],
            selectedId:'3',
            selectedCoin :{},
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeModal' , this.close)
      },
       async select(index) {
      this.selected = this.cryptoList[index];
      this.selected_for_price = this.selectedCoin[this.selectedCoinWallet.id].coin.code + "USDT";
      this.coinsDropdown = false;
      this.tradeData.market_id=this.cryptoList[index].id
      this.calcPrice()
      // this.netIndex = this.markets_list.data.data[index].coin.id;
      console.log(
        "selected",
        this.selected,
        this.priceData[this.selected_for_price]
      );
      // const net = await this.$axios.get(
      //   "http://adatether.6.vistadev.ir/api/markets/" +
      //     this.netIndex +
      //     "/networks"
      // );
      // this.nets = net.data.data;
      // console.log("price", this.selected_for_price);
    },
     showCoins() {
      this.coinsDropdown = !this.coinsDropdown;
    },
    calcPrice() {
        this.totalPrice=(this.priceData[this.selected_for_price].tomanSelling*this.tradeData.amount).toLocaleString()
    },
    async getNets() {
      this.state.loading =true
      const res = await this.$axios.get('/markets/'+this.selectedCoinWallet.id+'/networks',
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                 this.nets=res.data.data
                 console.log('nets',this.nets)
                 this.netId = res.data.data[0].id
      this.state.loading = false
    },
    seletctNet(index) {
      this.selectedNets=this.nets[index].network
      this.netId = ''
      this.netId = this.nets[index].id
      console.log('selectedNets',this.netId)
      this.coinsDropdown=false
    },
    async depositReq() {
      this.state.loading = true
      this.withdrawal_data.payment_method = 'CRYPTO'
      this.withdrawal_data.amount = this.tradeData.amount
      this.withdrawal_data.coin_id = this.selectedCoinWallet.id
      this.withdrawal_data.crypto_network_id = this.netId
      try {
      const res = await this.$axios.post(
                '/transactions/type/withdraw',
                this.withdrawal_data ,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
      )
      this.transactionId=res.data.data.id
      this.$emit('closeModal' , this.transactionId)
      console.log(this.withdrawal_data)
      }
      catch(err) {
        const error = err.response || err.toJSON();
        console.log(error)
        this.errorText=error.data.message 
        if(error.data.errors) {
          this.addressErr = error.data.errors.address[0]
        }
        if(error.data.status == 400) {
          this.state.authenErr =true
        }
        
        // this.amountErr = error.data.errors.amount[0]   
        // this.crypto_network_idErr = error.data.errors.crypto_network_id[0]                 
      }
      console.log(this.errorText)
      this.state.loading = false
    }
  },
  created() {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".coin-drop , .search-coins")) {
        this.coinsDropdown = false;
      }
      if (!event.target.closest(".nets-drop")) {
        this.coinNets = false;
      }
      if(!event.target.closest('.modal , .deposit_btn , .withdrawal_btn , .search-coins')) {
              this.$emit('closeModal' , this.close)
            }
            else  {
                return true
            }
    });
  },
  mounted() {
      console.log('list',this.cryptoList)
      this.cryptoList.forEach(element => {
        this.selectedCoin[element.coin_id]=element
        
      })
      this.getNets()
        console.log('selectedCoin' , this.selectedCoin[this.selectedCoinWallet.id])
        console.log('selectedCoinWallet' , this.selectedCoinWallet)
        this.selected_for_price = this.selectedCoin[this.selectedCoinWallet.id].coin.code + "USDT";
        ++this.test
  }
  }
</script>

<style lang="scss" scoped>
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
    min-height: 200px;
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
    box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
    border-radius: 8px;
    position: relative;
    padding: 24px;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .message-title {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
  }
  .inputs-container {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;
    column-gap: 16px;
    margin-top: 35px;
  }
  .deposit-btn {
      height: 40px;
      width:100%;
      border-radius: 6px;
      font-weight: bold;
    font-size: 16px;
    background: transparent;
      border: none;
      color: white;
      background: #FF2E21;
  }
  .btns {
      display: flex;
      justify-content: space-around;
      flex-grow: 1;
      width: 100%;
      align-items: center;
  }
  .coin-type-label {
    position: relative;
    }
    .coin-type-label > img {
    position: absolute;
    top: 30px;
    left: 10px;
    cursor: pointer;
    }
    .selected-crypto {
        height: 40px;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        column-gap: 5px;
        }
    .form-text {
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        margin: 0;
        font-size: clamp(14px, 1vw, 16px);
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
    }
    .form-text-amount {
        font-weight: normal;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        direction: ltr;
    }
    .buy-sell-form {
        // width: 90%;
        // margin: 0 auto;
        /* display: grid; */
        row-gap: 8px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding: 10px 0;
    }
    .exchange-inputs-container {
        width: 100%;
        // height: 520px;
        // background: #2f3239;
        // background: #2f3239;
    }
    .search-coins {
        height: 158px;
        overflow: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #3A3D44;
        border-radius: 5px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        padding: 0 10px;
    }
    .search-coins::-webkit-scrollbar {
        display: none;
    }
    .myInput-container {
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0;
        width: 100%;
        background: linear-gradient(180deg, #15161a 0%, #15161a 100%);
        padding: 0 10px;
    }
    #myUL {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

#myUL li a {
  border: 1px solid #ddd;
  margin-top: -1px; /* Prevent double borders */
  background-color: #f6f6f6;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: block;
}
#myUL li img {
  width: 25px;
  height: 25px;
}
#myUL li a:hover:not(.header) {
  background-color: #eee;
}
.drop-row {
  display: flex;
  column-gap: 10px;
  width: 250px;
  height: 30px;
  align-items: center;
  border: none;
  /* padding: 0 10px; */
  /* margin-right: 20px; */
}
.drop-row-search {
  background: #2f3239;
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  border: none;
}
.drop-row-ex:nth-child(even) {
  background-color: #1d1e23;
}
.drop-row-ex:nth-child(odd) {
  background-color: #151619;
}
.drop-row-ex img {
  width: 25px;
  height: 25px;
}
.drop-row-ex {
  display: flex;
  column-gap: 10px;
  width: 200px;
  height: 40px;
  align-items: center;
  border: none;
  padding: 0 10px;
  /* background-color: rgba(255, 255, 255, 0.04); */
}
#myInput {
  background-image: url("../../assets/search.png");
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 25px;
  font-size: 12px;
  position: sticky;
  top: 0;
  background-color: #2f3239;
  border-radius: 5px;
  color: white;
  padding-right: 20px;
  border: none;
  margin: 5px 0;
}
.selected-img {
  width: 25px;
}
</style>
