<template>
  <div >
    <Loading v-if="this.state.loading"/>
    <router-view></router-view>
  </div>
</template>
<script>
    import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
import Loading from './components/Profile/Loading.vue'
export default {
  name:"App",
  components: { 
    Loading
   },data() {
     return {
        echo : ''
     }
   },

  created() {

    Pusher

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      wsHost: '157.90.74.59',
      wsPort: 6001,
      forceTLS: true,
      cluster: 'mt1',
      disableStats: true,
    });
 this.echo.channel(`realtime-price`)
    .listen('RealTimePriceEvent', (e) => {
      e.prices.forEach(element => {
        this.state.price[element.market] = element
       this.state.price=e;
      });
      this.state.price=e;
      this.state.reload = !this.state.reload
      // console.log('prices',this.state.price)
        
        // console.log('price in state',this.state.price.prices[1].market)
    });
  },
//   beforeDestroyed() {
// this.echo.leaave('RealTimePriceEvent')
//   }
  
}
</script>
<style>

</style>
