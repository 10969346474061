<template>
   <div class="container">
       <span class="title"> چرا آداتتر؟
           <hr class="title-hr">
       </span>
       
       <div class="boxes">
           <div class="speed">
               <img class="boxes-img" src="@/assets/speed.png" alt="">
               <div class="text-boxes-container">
                    <p class="boxes-title">سرعت انجام معاملات</p>
                    <p class="boxes-describe">آداتتر، تمام تلاش خود را به کار می گیرد تا معاملات شما به سرعت و راحتی انجام شود.</p>
               </div>
           </div>
           <div class="support">
               <img class="boxes-img" src="@/assets/support.png" alt="">
               <div class="text-boxes-container">
                    <p class="boxes-title">پشتیبانی 24 ساعته</p>
                    <p class="boxes-describe">پشتیبانان آداتتر، هرزمانی پاسخگوی سوالات و مشکلات شما هستند.</p>
               </div>
           </div>
           <div class="security">
               <img class="boxes-img" src="@/assets/security.png" alt="">
               <div class="text-boxes-container">
                    <p class="boxes-title">امنیت معاملات شما</p>
                    <p class="boxes-describe">تیم آداتتر، با استفاده از نیروهای متعهد و حرفه ای بستری امن را برای انجام معاملات شما فراهم آورده است.</p>
                </div>
           </div>
       </div>
   </div>
</template>

<script>
export default {
  name: 'HomePage',
  components :{
    
  }}
</script>

<style scoped>
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
        margin: 0;
    }
    .title-hr {
        width: 200px;
        height: 1px;
        background-image:linear-gradient(90deg , rgba(255, 255, 255, 0), white , rgba(255, 255, 255, 0));
        border: none;
    }
    .boxes{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        min-height: 438px;
        align-items: center;
        column-gap: 10px;
    }
    .speed , .support , .security {
        max-width: 335px;
        min-height: 193px;
        background: #151515;
        border-radius: 5px;
        justify-content: center;
        /* height: 200px; */
        position: relative;
        height: auto;
        display: flex;
        min-width: 270px;
        /* max-height:max-content; */
    }
    .support {
        align-self: flex-end;
    }
    .text-boxes-container {
        margin-top: 40px;
    }
    .boxes-title {
        font-weight: 500;
        font-size: 18px;
        color: #DBABA7;
        text-align: center;
    }
    .boxes-describe {
        font-weight: normal;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        width: 90%;
        margin: auto;
        margin-bottom: 10px;
    }
    .boxes-img {
        position: absolute;
        top: -70px;
        /* right: 30%; */
        background: #151515;
        padding: 20px;
        border-radius: 50%;
        width: 85px;
        height: 90px;
    }
    @media only screen and (max-width:1000px) {
        .boxes {
        flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 90px;
    }
    .support {
        align-self: center;
    } 
    .container {
        row-gap: 80px;
    }
    
    }
    
</style>
