<template>
    <div  class="home">
      <NavbarHome />
      <div v-if="$route.path=='/'" class="home">
        <Baner v-model='cryptoList.data.data' :key='reload'/>
        <CryptoBox v-model='cryptoList.data.data' />
        <WhyAdateter/>
        <Steps/>
        <BlogItems/>
        <div class="start-button-div">
          <router-link to="/subscribe">
          <button class="start-button">
            همین حالا شروع کنید
          </button>
          </router-link>
        </div>
        <Footer/>
      </div>
      <FAQ v-if="$route.path=='/FAQ'"/>
      <About v-if="$route.path=='/about'"/>
      <Contact v-if="$route.path=='/contact'"/>
      <Policy v-if="$route.path=='/policy'"/>
    </div>
</template>

<script>
import Baner from "./Baner.vue"
import CryptoBox from './CryptoBox.vue'
import WhyAdateter from './WhyAdateter.vue'
import Footer from './Footer.vue'
import Steps from './Steps.vue'
import BlogItems from "./BlogItems.vue"
import NavbarHome from './NavbarHome.vue'
import FAQ from './FAQ.vue'
import About from './About'
import Contact from './Contact'
import Policy from './Policy'
export default {
  name: 'HomePage',
  components :{
    Baner,
    CryptoBox,
    WhyAdateter,
    Footer,
    Steps,
    BlogItems,
    NavbarHome,
    FAQ,
    About,
    Contact,
    Policy
  },
  data() {
    return {
      cryptoList:[{}],
      reload:0
    }
  },
  methods: {
   
    async getCrypto() {
          this.state.loading = true
          // "http://adatether.6.vistadev.ir/api/markets?status=ACTIVATED&size=500"
          // http://adatether.6.vistadev.ir/api/coins/public
                 this.cryptoList = await this.$axios.get('/markets?status=ACTIVATED&size=500')
                 for(var i=0 ; i <= this.cryptoList.data.data.length-1 ; i++) {
                     if(this.cryptoList.data.data[i].code=='TOMAN') {
                         this.cryptoList.data.data.splice(i , 1)
                     }
                     
                 }
                //  this.cryptoList.data.data.forEach(el => {
                //      for(var i=0 ; i <=this.state.price.prices.length-1; i++ ) {
                //          if(el.code+'USDT'==this.state.price.prices[i].market) {
                //             el.buy_price=this.state.price.prices[i].buying;
                //             el.percent_change=this.state.price.prices[i].percentChange;
                //             el.toman_buying=this.state.price.prices[i].tomanBuying;
                //             el.toman_selling=this.state.price.prices[i].tomanSelling;
                //          }
                //      }
                //  });
                console.log(this.cryptoList)
                this.reload =1
                this.state.loading = false
            },

  },
  mounted() {
     this.getCrypto()
    //  console.log(this.cryptoList)
  },
  }
</script>

<style scoped>
  /* .home {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  } */
  .home {
    background-color: #2F3239;
    background-image: url('../../assets/bg-pattern.png');
    background-size:cover;
    display: flex;
    flex-direction: column;
    row-gap: 80px;
  }
  .start-button-div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .start-button {
    border: 0;
    background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
    border-radius: 5px;
    width: 160px;
    height: 45px;
    font-weight: 500;
    font-size: 16px;
  }
</style>
