<template>

    <div class="container-dashboard">
        <!-- <NotificationBox class="NotificationBox" v-if="notificationModal"/> -->
        <div>
            <!-- v-model="test" :value="test" @input="e => test = e" -->
            <p class="dashboard-title">داشبورد</p>
            <!-- <p :key="value.name">'injja'{{value.profile.name}}</p> -->
            <!-- <p @click="$emit('input',++data)">{{value}}</p> -->
        </div>
        <div class="accountlevels-container">
            <perfect-scrollbar class="scrollbar">
            <AccountLevels class="account-levels" v-model="value.rank"/>
            </perfect-scrollbar>
        </div>
        <div class="information-containers">
            <div class="personal">
                <div class="name-number">
                    <div class="name">
                        <!-- {{PersonalInformation.sync.name}} -->
                        <p>
                            {{value.profile.name}}
                        </p>
                    </div>
                    <div class="number">
                        <p class="info-title">{{value.profile.mobile}}</p>
                    </div>
                </div>
                <div class="email">
                    <img class="email-img" src="@/assets/email.svg" alt="">
                    <p class="personal-contact">{{value.email}}</p>
                </div>
                <div class="credit-container">
                    <div class="credit">
                        <div class="credit-img">
                            <img src="@/assets/credit.svg" alt="">
                            <p class="personal-contact">{{value.profile.national_code}}</p>
                        </div>
                        <router-link to="/account">
                        <div class="editCredit-container">
                            <p class="editCredit-p">ویرایش</p>
                            <img class="editCredit" src="@/assets/editcredit.png" alt="">
                            
                        </div>
                        </router-link>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div class="wallet">
                <div class="wallet-amount">
                    <p class="info-title">
                        موجودی کیف پول شما
                    </p>
                    <p class="info-title" v-if="value.credits != ''">
                        <!-- {{value.credit.credit.toLocaleString()}} -->
                    </p>
                    <p class="info-title" v-else>
                        0
                    </p>
                </div>
                <div class="wallet-icons">
                    <div class="deposit-info-container" @click="showDeposit">
                        <p class="deposit-info-p">واریز</p>
                    <img class="deposit-info-img" src="@/assets/deposit-info.png" alt="">
                    
                    </div>
                    <div class="withdrawal-info-container" @click="showWithdrawal">
                        <p class="withdrawal-info-p">برداشت</p>
                    <img class="withdrawal-info-img" src="@/assets/withdrawal-info.png" alt="">
                    
                    </div>
                </div>
            </div>
            <div class="deal">
                <p class="info-title">خرید و فروش ارز دیجیتال</p>
               
                <div class="chart-info-container">
                    
                     
                     <p class="chart-info-p">معامله</p>
                       <router-link to="/exchange"> <img class="chart-info-img" src="@/assets/chart-info.png" alt="">
                    </router-link>
                    
                </div>
                
            </div>
        </div>
        <div>
            <OrdersHistory/>
        </div>
        <div class="tickets-announce">
            <div>
                <Tickets/>
            </div>
            <div>
                <Announcement/>
            </div>
        </div>
    </div>
</template>

<script>
import AccountLevels from "./AccountLevels.vue" 
import Announcement from './Announcement.vue'
import OrdersHistory from "./OrdersHistory.vue"
import Tickets from './Tickets.vue'
// import NotificationBox from './NotificationBox.vue'
 import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
 import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
export default {
  name: 'DashboardContent',
  components :{
    AccountLevels,
    OrdersHistory,
    Tickets,
    Announcement,
    PerfectScrollbar,
    // NotificationBox
  },
  data() {
      return {
          deposit:true,
          withdrawal:true,
          PersonalInformation:{},
          test:"",
          personData:this.value
            //  PersonalInformation: {
            //      profile : {
            //          name:"",
            //          mobile:"",
            //          national_code:""
            //      }
            //     //   name : "",
            //     //   mobile:"",
                  
            //     //   creditNumber:1354654654,
            //     //   walletAmount:54654564
            //   }
            
      }
  },
   props: ['value'],
   methods : {
       showDeposit() {
           this.$emit('childToDeposit', this.deposit)
       },
       showWithdrawal() {
           this.$emit('childToWithdrawal', this.withdrawal)
       },
       
    //    async personalinfo() {
    //        const personal = await this.$axios.get("https://adatether.6.vistadev.ir/api/users/init",
    //              {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //              this.PersonalInformation=personal.data.data.profile
    //              this.state=personal.data.data.settings.status
    //              console.log('داشبورد',this.PersonalInformation)
    //    }
    async markets() {

    }
   },
   mounted() {
    //    this.personalinfo()
    //    const market =  this.$axios.get("https://adatether.6.vistadev.ir/api/markets?marketType=bt",
    //              {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //              console.log('market',market)
    //     const marketActive =  this.$axios.get("https://adatether.6.vistadev.ir/api/markets?marketType=bt",
    //              {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //              console.log('marketActive',marketActive)
    //     const marketCopy =  this.$axios.get("https://adatether.6.vistadev.ir/api/markets/1/networks",
    //              {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //              console.log('marketCopy',marketCopy)
    //     console.log('پرسونال دیتا در داشبورد', this.value)
   },
     computed:{
      ll(personal){
          return personal
          }
  }
  }
</script>

<style scoped>
    .container-dashboard {
        width: 95%;
        margin: auto;
        display: flex;
    flex-direction: column;
    row-gap: 30px;
    }
    .dashboard-title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
    }
    .information-containers {
        display: flex;
        justify-content: space-between;
    }
    .personal , .wallet , .deal {
        background: rgba(21, 22, 25, 0.35);
        border-radius: 5px;
        /* width: 360px; */
        min-width: 30%;
        min-height: 120px;
    }
    .personal p {
        margin: 5px;
    }
    .name-number {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 90%;
        margin: auto;
    }
    .email  {
        display: flex;
        column-gap: 10px;
        align-items: center;
        width: 90%;
        margin: auto;
    }
    .email p , .credit p{
        /* color: #FFFFFF; */
        font-weight: normal;
font-size: 14px;
    }
    .name-number p {
        font-weight: 500;
        font-size: 18px;
        font-size: clamp(14px , 1vw , 18px);
        color: #FFFFFF;
    }
    .credit {
        display: flex;
        /* align-items: center; */
        width: 90%;
        margin: auto;
        justify-content: space-between;
    }
    .credit-img {
        display: flex;
        /* align-items: center; */
        column-gap: 10px;
    }
    .credit-img > img {
        height: 15px;
        margin: 10px 0;
    }
    .personal-contact , .family {
        color: #a5a5bb;
    }
    .info-title {
        color: #FFFFFF;
    }
    .wallet , .deal{
         position: relative;
    }
    .wallet::before , .deal::before {
        content: "";
         position: absolute;
      top: 10%;
      right: 0px;
      bottom: 0px;
      left: 35%;
        opacity: 0.05;
    }
    .wallet::before {
        background: url('../../assets/wallet-back.png') no-repeat;
    }
    .deal::before {
        background: url('../../assets/chart-back.png') no-repeat;
    }
    .deal {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*calck*/
        row-gap: 20px;
    }
    .wallet {
        display: flex;
        justify-content: space-between;

    }
    .wallet-icons , .wallet-amount {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .wallet-amount  {
        margin: 0 10px;
        text-align: center;
    }
    .wallet-icons {
        margin: 20px;
        align-items: flex-end;
    }
    .wallet-icons > img {
        width: 45px;
        height: 30px;
    }
    .tickets-announce {
        display: flex;
        column-gap: 55px;
        margin-bottom: 40px;
    }
    .tickets-announce > div {
        width: 100%;
    }
    .accountlevels-container {
        width: 100%;
        overflow: scroll;
        -ms-overflow-style: none;  
  scrollbar-width: none;
    }
    .accountlevels-container::-webkit-scrollbar {
        display: none;
    }
    .editCredit , .chart-info-img {
        width: 45px;
        height: 30px;
    }
    .withdrawal-info-img , .deposit-info-img {
        width: 45px;
        height: 30px;
    }
    .editCredit-container  , .chart-info-container{
        display: flex;
        /* flex-grow: 1; */
        justify-items: center;
        justify-content: space-around;
        align-items: center;
        /* column-gap: 10px; */
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
border-radius: 5px;
max-width: 122px;
max-height: 40px;
min-width: 45px;
min-height: 30px;
cursor: pointer;
z-index: 1;
    }
    .withdrawal-info-container , .deposit-info-container {
         display: flex;
        /* flex-grow: 1; */
        justify-content: space-around;
        align-items: center;
        border-radius: 5px;
max-width: 122px;
max-height: 40px;
min-width: 45px;
min-height: 30px;
cursor: pointer;
    }
    .withdrawal-info-container {
        background: #FF2E21;
    }
    .deposit-info-container {
        background: #0FA902;
    }
    .editCredit-p , .chart-info-p {
        font-weight: bold !important;
font-size: 16px;
color: black;
display: none;
    }
    .withdrawal-info-p , .deposit-info-p {
         font-weight: bold !important;
font-size: 16px;
color: #FFFFFF;
display: none;
    }
    .editCredit-container:hover > p , .withdrawal-info-container:hover >p  , .deposit-info-container:hover > p , .chart-info-container:hover > p{
        display: block;
    }
    .editCredit-container:hover  , .withdrawal-info-container:hover , .deposit-info-container:hover , .chart-info-container:hover{
        height: 40px;
        width: 122px;

    }
    .NotificationBox {
      position: absolute;
      top: 10px;
      left: 20px;
      z-index: 10;
  }
    
    @media only screen and (max-width:1000px) {
        .information-containers {
            flex-wrap: wrap;
            row-gap: 20px;
        }
        .personal , .wallet , .deal {
            flex-grow: 1;
            margin: 0 20px;
        }
        .tickets-announce {
            flex-wrap: wrap;
        }
    }
   @media only screen and (max-width:900px) {
       .scrollbar {
           visibility: hidden !important;
       }
       .scrollbar .account-levels {
           visibility: visible;
       }
   }
</style>
