<template>
    <div class="background">
        <!-- <NavbarHome class="navbarHome"/> -->
        <div class="baner-img-title container">
            <div class="baner-title">
                <div class="deal-div">
                    <p class="title-deal">آداتتر بستری سریع و امن برای انجام معاملات bashe</p>
                    <p class="describe-deal">به سرعت خرید و فروش کنید</p>
                   <router-link to="/subscribe"><button class="start-deal">شروع معامله</button></router-link> 
                </div>
                <div class="top-crypto" v-if="price[`${value[0].coin.code}USDT`]" >
                    <div class="top-crypto-title">
                        <img class="top-crypto-image" :src=value[0].coin.logo alt="">
                        <p class="top-crypto-name"> {{value[0].coin.label}}</p>
                        <p class="top-crypto-name"> {{value[0].coin.code}}</p>
                    </div>
                    <p :class="{'top-crypto-change-pos': +price[`${value[0].coin.code}USDT`].percentChange > 0,
                                'top-crypto-change-neg': +price[`${value[0].coin.code}USDT`].percentChange < 0
                        }">
                        {{+price[`${value[0].coin.code}USDT`].percentChange }}
                        <span v-if="+price[`${value[0].coin.code}USDT`].percentChange > 0">+</span>
                    </p>
                    <p class="top-crypto-price">
                        {{price[`${value[0].coin.code}USDT`].tomanSelling.toLocaleString()}} <span>تومان</span>
                    </p>
                </div>
            </div>
            <div class="baner-image">
                <img class="ada-image" src="@/assets/ada.png" alt="">
            </div>
        </div>
        <Slider v-model="value"/>
    </div>
</template>

<script>
import Slider from "./Slider.vue"
import crypto from "../../library/crypto.js"
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
// import NavbarHome from './NavbarHome.vue'
export default {
  name: 'Baner',
  components :{
      Slider,
    // NavbarHome
  },
  props : [
      'value'
  ],
  data() {
      return {
          cryptoInformation:crypto,
          indexCrrypto:0,
          price:[]
      }
  },
  created() {

    Pusher

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      wsHost: '157.90.74.59',
      wssPort: 6001,
      forceTLS: false,
      useTLS:false,
      cluster: 'mt1',
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
    });
 this.echo.channel(`realtime-price`)
    .listen('RealTimePriceEvent', (e) => {
      e.prices.forEach(element => {
        this.price[element.market] = element
       this.price=e;
      });
      this.price=e;
      this.state.reload = !this.state.reload
    //   console.log('prices',this.state.price)
        
        // console.log('price in state',this.state.price.prices[1].market)
    });
  },
  mounted() {
      this.price=this.state.price
  }
  }
</script>

<style scoped>
    .navbarHome {
        background: #191B22 !important;
        backdrop-filter: none !important;
    }
    .background {
        background-image: url("../../assets/baner.png");
        background-size: cover;
        height: auto;
        background-color: linear-gradient(90deg, rgba(21, 22, 25, 0.5) 0%, rgba(21, 22, 25, 0) 100%);
        margin-top: -140px;
    }
    .baner-img-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 60px;
    }
    .deal-div {
        background: rgba(255, 255, 255, 0.04);
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(9px);
        border-radius: 10px;
        width: 640px;
        height: 288px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50vw;
        align-self: center;
        max-width: 640px;
    }
    @supports not (backdrop-filter: blur()) {
        .deal-div , .crypto , .top-crypto {
            background: #252833 !important;
        }
    }
    .title-deal {
        font-weight: 500;
        /* font-size: 24px; */
        font-size:clamp(14px , 2vw , 24px);
        color: #DBABA7;
        margin: 7px;
        text-align: center;
    }
    .describe-deal {
        font-weight: 500;
        /* font-size: 20px; */
        font-size:clamp(12px , 1vw , 20px);
        color:rgba(255, 255, 255, 0.6);
        margin: 7px;
    }
    .start-deal {
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border-radius: 5px;
        font-weight: 600;
        font-size: 16px;
        color: rgba(21, 22, 26, 1);
        border: none;
        width: 213px;
        height: 40px;
        margin: 30px;
    }
    .ada-image {
        width: 525px;
        height: 525px;
        max-width: 100%;
        object-fit: scale-down;
    }
    .baner-crypto {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        overflow: scroll;
    }
    .baner-title{
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .crypto {
        background: rgba(255, 255, 255, 0.04);
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(9px);
        border-radius: 5px;
        width: 305px;
        height: 91px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .positive-change , .negative-change {
        font-weight: 500;
        font-size: 18px;
    }
    .crypto-image {
        width: 57px;
        height: 57px;
        
    }
    .crypto-name-price {
        display: flex;
        flex-direction: column;
    }
    .crypto-price {
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);

    }
    .crypto-name {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }
    .crypto-name > p {
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
        margin: 0;
    }
    .positive-change {
        color: #0FA902;
    }
    .negative-change {
        color: #FF2E21;
        direction: ltr;
    }
    .top-crypto {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(255, 255, 255, 0.04);
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
        backdrop-filter: blur(9px);
        border-radius: 10px;
        padding: 0 20px;
    }
    .top-crypto-title {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
    .top-crypto-image{ 
        width: 60px;
        height: 60px;
    }
    .top-crypto-name {
        color: #FFFFFF;
        font-weight: 500;
        font-size: 24px;
    }
    .top-crypto-change-pos {
        font-weight: 500;
        font-size: 20px;
        color: #0FA902;
    }
    .top-crypto-change-neg {
        color: #FF2E21;
        font-weight: 500;
        font-size: 20px;
        direction: ltr;
    }
    .top-crypto-price {
        font-weight: 500;
        font-size: 18px;
        color: #DBABA7;
    }
    @media only screen and (max-width:1000px) {
        .baner-img-title {
            flex-wrap: wrap-reverse;
            margin-bottom: 20px;
        }
        .deal-div {
            width: 80vw;
        }
    }
    @media only screen and (max-width:600px) {
        .top-crypto {
            flex-direction: column;
        }
    }
</style>
