<template>
  <div class="about-body">
    <!-- <NavbarHome /> -->
    <div class="container">
      <div>
        <p class="contact-title">تماس با آداتتر</p>
      </div>
      <div class="contact-container">
        <div class="form-container">
          <p class="form-title">
            با آدا تتر در تماس باشید، دراولین فرصت پاسخگوی شما هستیم.
          </p>
          <form action="" @submit.prevent="send">
            <label for="">
              <span>ایمیل</span>
              <input
                type="text"
                v-model="message.email"
                @input="er.email = ''"
              />
              <span class="err-text" v-if="er.email">{{ er.email[0] }}</span>
            </label>
            <label for="">
              <span>موضوع</span>
              <input
                type="text"
                name=""
                id=""
                v-model="message.name"
                @input="er.name = ''"
              />
              <span class="err-text" v-if="er.name">{{ er.name[0] }}</span>
            </label>
            <label for="">
              <span>متن پیام</span>
              <textarea
                name=""
                id=""
                v-model="message.description"
                @input="er.description = ''"
              ></textarea>
              <span class="err-text" v-if="er.description">{{
                er.description[0]
              }}</span>
            </label>
            <button class="contact-form-button" type="submit">
              ارسال پیام
            </button>
          </form>
        </div>
        <div class="contact-info">
          <p
            class="contact-describe"
            v-for="(contacts, index) in contacts"
            :key="index"
            v-html="contacts.description"
          ></p>
          <div class="contact-list-container">
            <!-- <div class="contact-list">
                            <img src="@/assets/location.svg" alt="">
                            <p>
                                تهران خیابان نلسون ماندلا )جردن( ارمغان شرقی پلاک 39 واحد 16
                            </p>
                        </div> -->
            <div class="contact-list">
              <img src="@/assets/telephone.svg" alt="" />
              <p>{{social_data.phone.option_value}}</p>
              <!-- <p>0912-56456409</p> -->
            </div>
            <div class="contact-list">
              <img src="@/assets/email.svg" alt="" />
              <p>{{social_data.email.option_value}}</p>
            </div>
            <!-- <div class="contact-list">
              <img src="@/assets/whatsapp.svg" alt="" />
              <p></p>
            </div> -->
            <div class="image-contact-container">
              <div>
                <div class="contact-list">
                  <img src="@/assets/instagram.svg" alt="" />
                  <p>{{social_data.instagram.option_value}}</p>
                </div>
                <div class="contact-list">
                  <img src="@/assets/telegram.svg" alt="" />
                  <p >{{social_data.telegram.option_value}}</p>
                </div>
              </div>
              <div class="contact-image-container">
                <img class="contact-image" src="@/assets/contact.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NavbarHome from "./NavbarHome.vue";
export default {
  name: "HomePage",
  components: {
    // NavbarHome,
  },
  data() {
    return {
      contacts: [],
      message: {
        name: "",
        email: "",
        description: "",
        is_seen: true,
      },
      er: {},
      social_data:{}
    };
  },
  methods: {
    async content() {
      
      const getContent = await this.$axios.get(
        "/site-info"
      );
      
      this.contacts = getContent.data.data;
      console.log(getContent.data.data);
    },
    async info() {
      this.state.loading=true
        const res = await this.$axios.get("/options/social")
        const res2 = await this.$axios.get("/options/address")
        this.social_data=res.data.data
        this.social_data.forEach(element => {
            this.social_data[element.option_key]=element
        });
        res2.data.data.forEach(element => {
            this.social_data[element.option_key]=element
        });
        ++this.test
        console.log('ccccccccccc',this.social_data)
        this.state.loading=false
    },
    async send() {
      try {
        const res = await this.$axios.post(
          "/contacts",
          this.message
        );
        this.message.name = "";
        this.message.email = "";
        this.message.description = "";
        console.log(res);
      } catch (err) {
        const error = err.response || err.toJSON();
        console.log(error.data.errors);
        this.er = error.data.errors;
      }
    },
  },
  mounted() {
    this.content();
    this.info()
  },
};
</script>

<style scoped>
.about-body {
  background: #2f3239;
  background-image: url("../../assets/faq-background.png");
 min-height: calc(100vh - 60px);
  overflow: auto;
  margin-top: -80px;
}
.contact-container {
  display: flex;
  /* flex-wrap: wrap; */
  column-gap: 20px;
  row-gap: 20px;
}
.form-container {
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 200px;
  background: rgba(21, 22, 26, 0.7);
  border-radius: 5px;
  padding: 20px;
}
.contact-title {
  font-weight: 500;
  font-size: 20px;
  color: #dbaba7;
}
.form-title {
  font-weight: normal;
  /* font-size: 16px; */
  color: white;
  font-size: clamp(14px, 1vw, 16px);
}
input,
textarea {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  border: none;
}
form,
label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
form span {
  background: transparent;
  font-weight: normal;
  font-size: 16px;
  color: white;
}
textarea {
  resize: none;
  min-height: 179px;
}
.contact-info {
  width: 70%;
  min-width: 300px;
  background: rgba(21, 22, 26, 0.7);
}
.contact-form-button {
  background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
  border-radius: 5px;
  border: 0;
  height: 40px;
}
input {
  height: 40px;
  font-weight: normal;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}
textarea {
  font-weight: normal;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}
form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.contact-describe {
  font-weight: normal;
  font-size: 16px;
  color: white;
  width: 90%;
  margin: 30px auto;
  font-size: clamp(14px, 1vw, 116px);
}
.contact-list-container {
  width: 90%;
  margin: auto;
  font-weight: normal;
  font-size: 16px;
  color: white;
  position: relative;
}
.contact-list {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.contact-list p {
  font-size: clamp(14px, 1vw, 16px);
}
/* .contact-image-container {
        text-align: left;
        position: absolute;
        bottom: -50px;
        left: 0;
    } */
.contact-image {
  text-align: left;
  max-width: 100%;
}

.image-contact-container {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  column-gap: 20px;
}
.err-text {
  font-size: 12px;
  color: #ff2e21;
}
@media only screen and (max-width: 1000px) {
  .image-contact-container,
  .contact-container {
    flex-wrap: wrap;
  }
  .contact-info,
  .form-container {
    flex-grow: 1;
    max-width: 600px;
    margin: auto;
  }
}
</style>
