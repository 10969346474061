var crypto=[
    {
        "name":"اتريوم",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/ethereum.png'),
    },
    {
        "name":"بیت کوین",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":-0.45,
        "image":require('../assets/Coins/Bitcoin.png'),
    },
    {
        "name":"بیت کوین کش",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":-0.45,
        "image":require('../assets/Coins/BitcoinCash.png'),
    },
    {
        "name":"اتریوم کلاسیک",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/classicEther.png'),
    },
    {
        "name":"لایت کوین",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/litecoin.png'),
    },
    {
        "name":"ریپل",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/xrp.png'),
    },
    {
        "name":"دش کوین",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/dashcoin.png'),
    },
    {
        "name":"دوج کوین",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/dogecoin.png'),
    },
    {
        "name":"ترون",
        "ENname":"ETR",
        "price":875500,
        "buyPriceTooman":36456981,
        "sellPriceTooman":34987456,
        "buyPriceTeter":756,
        "sellPriceTeter":740,
        "change":0.45,
        "image":require('../assets/Coins/teron.png'),
    }
]
export default crypto