<template>
    <transition name="modal-fade" >
        <div class="modal-backdrop" >
          <div class="modal">
            <img @click="closeModale" class="close-icon" src="@/assets/close-modal.png" alt="">
            <span class="message-title">
                ثبت شناسه
            </span>
            <div class="body-container">
                <div class="address_image" v-html="info.address_image"></div>
                <label for="">
                <input type="text" :value="info.address">
                <img class="copy-Link-img" src="@/assets/copyLink.png" alt="" @click="copyAddress">
                <div v-if="alertAddress" class="alert-copy">
                         کپی شد
                    </div>
                </label>
                <label>
                  <input type="text" v-model="data.txId" placeholder="شناسه تراکنش">
                </label>
                <div class="memo_image" v-html="info.memo_image"></div>
                <label v-if="info.memo != ''">
                  <input type="text" placeholder="تگ" :value="info.memo">
                  <img class="copy-Link-img" src="@/assets/copyLink.png" alt="" @click="copyMemo">
                  <div v-if="alertMemo" class="alert-copy">
                         کپی شد
                    </div>
                </label>
                <label for="" v-if="info.memo != ''">
                  <input type="text" >
                </label>
                <button class="send_btn" @click="send">
                  تایید
                </button>
            </div>
          </div>
        </div>
    </transition>
</template>
<script>
export default {
    name:"TXModal",
    components:{},
    props:[
      "trsId"
    ],
    data() {
      return {
         close:false,
         info:{},
         data : {
           txId:'',
         },
         
         alertAddress:false
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeModal' , this.close)
      },
      async depoInfo() {
          const res = await this.$axios.get(
              '/networks/1/address',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          console.log(res)
          this.info = res.data.data
          console.log('info',this.info)
      },
      async send() {
        this.state.loading = true
        try {
          const res = await this.$axios.patch(
          '/transactions/'+this.trsId+'/tx-id',
          this.data , 
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
        )
        console.log(res)
        this.state.loading = false
        this.state.success = true
        }
        catch(err) {
          const error = err.response || err.toJSON();
          console.log(error)
        }
        this.state.loading = false
      },
      copyAddress() {
        navigator.clipboard.writeText(this.info.address);
            this.alertAddress=true
            setTimeout(() => { this.alertAddress=false; }, 1500);
      },
      copyMemo() {
        navigator.clipboard.writeText(this.info.memo);
            this.alertMemo=true
            setTimeout(() => { this.alertMemo=false; }, 1500);
      }
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal , .sell-bit-btn , .buy-bit-btn , .complete_btn')) {
              this.$emit('closeModal' , this.close)
            }
            else  {
                return true
            }
            
          } )
  },
  mounted() {
      this.depoInfo()
  }
}
</script>
<style lang="scss" scoped>
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 450px;
    min-height: 150px;
    background: black;
    border-radius: 8px;
    position: relative;
    padding: 24px;
    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
    justify-content: center;
    row-gap: 15px;
    overflow: scroll;
     -ms-overflow-style: none;
    scrollbar-width: none; 
  }
   .modal::webkit-scrollbar {
     display: none;
   }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  .message-title {
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
  }
  .body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .body-container::v-deep .address_image {
      max-width: 200px;
      svg {
          width: 100%;
          height: auto;
      }
  }
  .body-container::v-deep .memo_image {
      max-width: 150px;
        svg {
          width: 100%;
          height: auto;
        }
  }
  .address_image {
    width: 200px;
  }
  .body-container {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
  }
  .send_btn {
    width: 100%;
    height: 35px;
    background: #0FA902;
    border-radius: 5px;
    color: #ffffff;
    border: none;
  }
  .copy-Link-img {
        position: absolute;
        left: 10px;
        top: 8px;
        cursor: pointer;
    }
    .alert-copy {
        position: absolute;
        font-size: 12px;
        color:white;
        background: rgba(15, 169, 2, 0.2);
        border: 1px solid green;
        border-radius: 5px;
        padding: 5px;
        top: -10px;
        left: 10px;
    }
</style>