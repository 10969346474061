import Vue from 'vue';

Vue.use(require('vue-cookies'))

const that = Vue.prototype
const state = Vue.observable({
    // token: localStorage.token || Vue.$cookies.get('--abcd14589') || false,
    token:'81|zxYZX15TZ6v9w0zwAXULH9tgmCZovau1B1rmXUG9',
    email:"",
    status:"",
    loading: false,
    success : false,
    price:{},
    authenErr : false,
    reload:false
})

that.state = state
export default state