import Vue from 'vue'
import App from './App.vue'
import router from './library/index.js'
import axios from 'axios'
import './library/state'
import VueApexCharts from 'vue-apexcharts'
import TrendChart from "vue-trend-chart";
import VueCookies from 'vue-cookies';
import "vue-select/dist/vue-select.css";
Vue.prototype.$axios = axios
// import $ from 'jquery'
import "./style/index.scss"
import './library/GlobalFunctions'
import './library/GeorgianToJalali'
import './library/axios.js'
import vSelect from 'vue-select'
Vue.component('apexchart', VueApexCharts)
Vue.use(VueApexCharts)
Vue.use(TrendChart);
Vue.use(VueCookies);
Vue.component("v-select", vSelect);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
