<template>
  <div class="support">
    <p class="support-title">
      پشتیبانی
    </p>
    <div class="support-chat-table">
      <div class="chat">
        <div class="chat-title">
          <select name="" id="" :options="options" v-model="message_title">
            <option value="">احراز هویت</option>
            <option value="">خرید ارز </option>
            <option value="">فروش ارز</option>
          </select>
        </div>
        <div class="message-content" :key="test">
          <template v-if="messages.length > 0" >
            <div
              class="message-costumer"
              v-for="(message, index) in messages"
              :key="index"
            >
              <svg height="10" width="15">
                <polygon points="0,0 15,0 15,10" />
              </svg>
              <p>{{ message.body }}</p>
              <span> - {{ message.created_at }}</span>
            </div>

            <!-- <div class="message-support" v-if="this.reply">
              <svg height="10" width="15">
                <polygon points="0,0 15,0 0,10" />
              </svg>
              <p>{{ this.messageSupport }}</p>
              <span> {{ this.support }} - {{ this.date }}</span>
            </div> -->
          </template>
        </div>
        <div for="" class="message-label">
          <button @click="addMessage" class="message-img" :disabled="messageBody==''">
            <img src="@/assets/message.png" alt=""  />
          </button>
          <textarea
            name=""
            class="input-message resize-ta"
            v-model="messageBody"
            @input="setHeight"
            @change="setMinHieght"
          />
          <!-- <input type="text" class="message-input" v-model="messageContentCostumer" > -->
        </div>
      </div>
      <div class="table">
        <table>
          <tr class="tr-head">
            <td
              class="td-head"
              v-for="(heade, index) in tableHeade"
              :key="index"
              :class="heade.class"
            >
              {{ heade.title }}
            </td>
          </tr>
          <tr
            class="tr-body"
            v-for="(messageList, index) in list.data.data"
            :key="index"
            @click="showTicket(index)">
            <td>
              <p class="status-1" v-if="messageList.status == 'PENDING'">
                در انتظار پاسخ
              </p>
              <!-- <p class="status-2" v-else>پاسخ داده شده</p> -->
            </td>
            <td class="td-title">
              {{ messageList.title }}
            </td>
            <td class="td-date">
              {{ messageList.created_at }}
            </td>
          </tr>
        </table>

        <div class="add-ticket-container">
          <button class="add-ticket" v-if="addNew" @click="addNewFun">
            ایجاد تیکت جدید
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Support",
  components: {},
  data() {
    return {
      addNew:false,
      titleNew:true,
      test:0,
      messageContentCostumer: "",
      messageBody: "",
      message_title: "",
      messageContent: [],
      newTicket: [],
      ticketId: null,
      ticketDetail: [],
      date: null,
      name: "مرجان خلیل پور",
      suport: "پشتیبانی",
      messageSupport: "",
      reply: false,
      support: "پشتیبانی",
      options: ["احراز هویت", "خرید ارز", "فروش ارز"],
      tableHeade: [
        {
          title: "وضعیت",
        },
        {
          title: "عنوان",
        },
        {
          title: "زمان",
          class: "td-date",
        },
      ],
      messages: [],
      list: [],
    };
  },
  methods: {
    async addMessage() {
      this.addNew=true
      const date = new Date();
      const localDate = date.toLocaleString();
      this.date = localDate;
      this.messageContent = {
        // status: 1,
        // title: this.titleMessage,
        created_at: localDate,
        body: this.messageBody,
        title: this.message_title,
      };
      this.messages.push(this.messageContent);
      console.log(this.messageContent);
      this.calcHeight("");
      this.messageBody = "";
      console.log("پیام ها", this.messageContent);
      console.log(this.messageContent.length);
      let address = this.ticketId === null
        ? "/tickets"
        : "/tickets/" + this.ticketId;
      this.newTicket = await this.$axios.post(address, this.messageContent, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
      this.ticketId = this.newTicket.data.data.id;
      console.log("new-ticket", this.newTicket);
      console.log("id", this.ticketId);
    },
    setMinHieght() {
      let textarea = document.querySelector(".resize-ta");
      textarea.style.height = 40 + "px";
    },
    setHeight() {
      let textarea = document.querySelector(".resize-ta");
      if (textarea.value !== "") {
        textarea.style.height = this.calcHeight(textarea.value) + "px";
        console.log(textarea.style.height);
      } else {
        this.calcHeight(0);
      }
      // textarea.value.lenght=0
    },
    calcHeight(value) {
      let textarea = document.querySelector(".resize-ta");
      if (textarea.value !== "") {
        let numberOfLineBreaks = (value.match(/\n/g) || []).length;
        // min-height + lines x line-height + padding + border
        let newHeight = 20 + numberOfLineBreaks * 20 + 20;
        return newHeight;
      } else {
        let newHeight = 0;
        return newHeight;
      }
    },

    async giveList() {
      this.messages=''
      this.list = await this.$axios.get(
        "/tickets",
        { headers: { Authorization: `Bearer ${this.$cookies.get('token')}` } }
      );
      // this.list=getList.data.data
      //  console.log('support',this.list)
    },
    async showTicket(index) {
      const id = this.list.data.data[index].id;
      this.ticketId = id
      this.addNew=true
      this.ticketDetail = await this.$axios.get(
        "/tickets/" + id,
        { headers: { Authorization: `Bearer ${this.$cookies.get('token')}` } }
      );
      console.log('message',this.ticketDetail);
       this.messages = this.ticketDetail.data.data
      ++this.test
    },
    addNewFun() {
      this.addNew=false
      this.messages=''
    }
  },
  mounted() {
    this.giveList();
  },
};
</script>

<style scoped>
.support {
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.support-title {
  font-weight: 500;
  font-size: 20px;
  color: #dbaba7;
  margin-bottom: 0;
}
.support-chat-table {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  column-gap: 30px;
  row-gap: 30px;
}
.chat,
.table {
  background: rgba(21, 22, 26, 0.7);
  border-radius: 5px;
  min-height: 593px;
  min-width: 210px;
  width: 100%;
  flex-grow: 1;
}
.chat {
  flex-basis: 570px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
}
.chat-title {
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-message {
  display: flex;
  flex-grow: 1;
  border: none;
  box-sizing: border-box;
  border-radius: 2px;
  /* overflow: hidden; */
  height: 40px;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  max-height: 150px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* background: transparent; */
  background: rgba(255, 255, 255, 0.05) !important;
}
.input-message::-webkit-scrollbar {
  display: none;
}
.input-message[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}
.table {
  flex-basis: 540px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
table {
  text-align: center;
  width: 100%;
  border-collapse: collapse;
}
.tr-head {
  height: 70px;
}
.td-head {
  font-weight: 500;
  font-size: 16px;
  color: rgba(255, 176, 7, 1);
}
tr:nth-child(odd) {
  background: #1d1f23;
}
tr:nth-child(even) {
  background: #22252a;
}
.tr-body {
  height: 40px;
  cursor: pointer;
}
td p {
  margin: 0;
}
.status-1 {
  font-weight: normal;
  font-size: 16px;
  color: rgba(255, 176, 7, 1);
}
.status-2 {
  font-weight: normal;
  font-size: 16px;
  color: #0fa902;
}
.td-title,
.td-date {
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.add-ticket {
  background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
  border-radius: 5px;
  width: 335px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  border: none;
}
.add-ticket-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
}
select {
  background: url("../../assets/caretdown.png") no-repeat;
  background-color: #2f3239;
  border-radius: 5px;
  width: 150px;
  height: 30px;
  color: #ffffff;
  border: none;
  text-align: center;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
option {
  text-align: center;
  border: none;
}

option:nth-child(odd) {
  background: #1d1f23;
}
option:nth-child(even) {
  background: #22252a;
}
.message-img {
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border: none;
}
.message-img:disabled img{
  opacity: 0.5;
}
.message-img > img {
  width: 24px;
  height: 21px;
}
.message-label {
  width: 100%;
  margin: 0 auto;
  height: 40px;
  display: flex;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.05); */
  border-radius: 5px;
  bottom: 10px;
  align-items: flex-end;
}
.message-input {
  margin: 8px;
  width: 100%;
  background: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.7);
}
.message-input:focus {
  border: none;
}
.message-content {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  justify-content: flex-start;
  flex-grow: 1;
  margin: 20px 10px;
  height: 500px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.message-content::-webkit-scrollbar {
  display: none;
}
.message-costumer {
  width: 60%;
  margin-left: auto;
  height: auto;
  font-weight: normal;
  font-size: 14px;
  background: rgba(21, 22, 26, 1);
  min-height: 120px;
  padding: 10px;
  color: #ffffff;
  position: relative;
}
.message-costumer p {
  margin: 0;
  font-size: clamp(12px, 1.5vw, 14px);
}
.message-costumer span {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: rgba(255, 255, 255, 0.5);
  font-size: clamp(12px, 1.5vw, 14px);
}
.message-costumer svg {
  position: absolute;
  background-origin: 0;
  right: 0;
  bottom: -10px;
}
.message-costumer polygon {
  fill: rgba(21, 22, 26, 1);
}
.message-support {
  width: 60%;
  margin-right: auto;
  height: auto;
  font-weight: normal;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.1);
  min-height: 120px;
  padding: 10px;
  color: #ffffff;
  position: relative;
}
.message-support p {
  margin: 0;
  font-size: clamp(12px, 1.5vw, 14px);
}
.message-support span {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgba(255, 255, 255, 0.5);
  font-size: clamp(12px, 1.5vw, 14px);
}
.message-support svg {
  position: absolute;
  background-origin: 0;
  left: 0;
  bottom: -10px;
}
.message-support polygon {
  fill: rgba(255, 255, 255, 0.1);
}
@media only screen and (max-width: 1000px) {
  .support-chat-table {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 500px) {
  .td-date {
    display: none;
  }
}
</style>
