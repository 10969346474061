<template>
  <div class="trendChart-parent">
    <!-- <svg
      style="width: 0; height: 0; position: absolute"
      aria-hidden="true"
      focusable="false"
    >
      <defs>
        <linearGradient id="btcFill" x1="1" x2="1" y1="0" y2="1">
          <stop offset="0%" stop-color="var(--main-color)" />
          <stop offset="100%" stop-color="#ffffff00" />
        </linearGradient>
      </defs>
    </svg> -->
    <TrendChart :key="test"
      :datasets="data"
      :grid="{
        verticalLines: true,
        horizontalLines: true,
      }"
      
      :min="5000"
      
    >
    </TrendChart>
  </div>
</template>
<script>
export default {
  name: "TradingChart",
  components: {},
  props: ["trend"],
  data() {
    return {
      data:[],
      test:0,
      chartData:[]
    };
  },
  methods: {},
  mounted() {
      if(this.trend) {
      for(var i=0 ; i < 20; i++) {
        //   this.chartData[i]=Math.round(parseFloat(this.trend[i]))
        this.chartData[i]=this.trend[i]/10
      }
      this.data = [
        {
          data: this.chartData,
          smooth: true,
          fill: true,
        },
      ]
      ++this.test
      }
        else {
            this.chartData=[0 , 0 , 0 , 0 , 0]
        }
    // console.log("aaaaaaaaaaaaaaaaa", this.data);
  },
};
</script>
<style lang="scss">
.vtc {
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.vtc * {
  stroke-width: 3px;
  stroke: rgba(255, 176, 7, 1);
  
}
.vertical {
  display: none;
}
.curve {
  height: 100%;
  display: flex;
  flex-grow: 1;

}
.curve path:first-of-type {
  display: none;
}
// .bitcoin-price .vtc * {
//   height: 100%;
//   font-size: 12px;
//   width: 70%;
//   // transform: scale(0.5);
// }

// @media (min-width: 699px) {
//   .bitcoin-price .vtc {
//     height: 60%;
//   }
// }

// .bitcoin-price .grid line,
// .bitcoin-price .labels line {
//   stroke: rgba(246, 145, 25, 0.5);
// }

// .bitcoin-price .x-labels .label text {
//   display: none;
// }

// .bitcoin-price .x-labels .label line {
//   opacity: 0.3;
// }

// .bitcoin-price .x-labels .label:nth-child(6n + 1) text,
// .bitcoin-price .x-labels .label:first-child text {
//   display: block;
// }

// .bitcoin-price .x-labels .label:nth-child(6n + 1) line,
// .bitcoin-price .x-labels .label:first-child line {
//   opacity: 1;
// }

// .bitcoin-price .curve-btc .stroke {
//   stroke: var(--main-color);
//   stroke-width: 2;
// }

// .bitcoin-price .curve-btc .fill {
//   fill: url(#btcFill);
//   fill-opacity: 0.5;
// }
</style>
