<template>
    <div>
        <div class="history-orders-title">
           <p>تاریخچه سفارشات</p> 
        </div>
        <div class="table-container">
            <table>
                <tr class="head-tr">
                    <td class="td-hed">
                        شماره سفارش
                    </td>
                    <td class="td-hed">
                        نوع سفارش
                    </td>
                    <td class="td-hed">
                        رمز ارز
                    </td>
                    <td class="td-hed">
                        مقدار
                    </td>
                    <td class="td-hed">
                        قیمت واحد <span class="table-head-currency">تومان</span>
                    </td>
                    <td class="td-hed">
                        قیمت کل <span class="table-head-currency">تومان</span>
                    </td>
                    <td class="td-hed">
                        زمان 
                    </td>
                    <td class="td-hed">
                        بیگیری
                    </td>
                    <td class="td-hed">
                        وضعیت
                    </td>
                </tr>
                <tbody>
                <template v-for="(OrdersHistory , index) in orders"  >
                <tr v-if="orders.status != 'PENDING' && orders != ''" :key="index" class="tr-body-orders">
                    <td>
                        {{OrdersHistory.id}} 
                    </td>
                    <td :class="{'td-buy' : OrdersHistory.type==='BUY' , 'td-sell':OrdersHistory.type==='SELL'}">
                        <span v-if="OrdersHistory.type==='BUY'">
                            خرید
                        </span>
                        <span v-if="OrdersHistory.type==='SELL'">
                            فروش
                        </span>
                    </td>
                    <td>
                        {{OrdersHistory.market.coin.label}}
                    </td>
                    <td>
                        {{OrdersHistory.amount}}
                    </td>
                    <td>
                        {{parseFloat(OrdersHistory.price).toLocaleString()}}
                    </td>
                    <td>
                        {{OrdersHistory.total.toLocaleString()}}
                    </td>
                    <td>
                        {{$G2J(OrdersHistory.created_at)}} 
                    </td>
                    <td>
                        {{OrdersHistory.order}}-
                    </td>
                    <td>
                        <img v-if="OrdersHistory.status=='ACCEPTED'" src="@/assets/status-done.png" alt="">
                        <img v-else src="@/assets/status-fail.png" alt="">
                    </td>
                </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
  name: 'OrdersHistory',
  components :{
    
  },
  data() {
      return {
          orders:[],
      }
  },
  methods: {
      async getOrders() {
    this.state.loading = true
        const res = await this.$axios.get(
            '/orders',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
        )
        this.orders=res.data.data
        // console.log(this.orders)
        this.state.loading = false
    },
  },
  mounted() {
      this.getOrders()
  }
  }
</script>

<style scoped>
    .history-orders-title {
        font-weight: 500;
        font-size: 18px;
        color: #DBABA7;
    }
  table {
      width: 100%;
      text-align: center;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
border-radius: 5px;
height: auto;
border-collapse: collapse;
min-width: 700px;
  }
  tr:nth-child(odd) {
      background: #2F3239;
  }
  tr:nth-child(even) {
      background: #272A30;
  }
  .tr-body-orders {
      height: 40px;
      font-weight: normal;
/* font-size: 16px; */
color: #FFFFFF;
font-size: clamp(12px , 1.5vw , 16px);
  }
  .td-buy {
     
        color: #0FA902;

  }
  .td-sell {
 color: #FF2E21;
  }
  .head-tr {
      height: 60px;
      
  }
  .head-tr {
      font-style: normal;
        font-weight: 500;
        color: rgba(255, 176, 7, 1);
  }
  .table-head-currency {
      font-weight: normal;
        font-size: 14px;
        color: rgba(255, 176, 7, 0.6);
  }
  .table-container {
      width: 100%;
      overflow: scroll;
      -ms-overflow-style: none;
  scrollbar-width: none; 
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }
  .table-container::-webkit-scrollbar {
      display: none;
  }
</style>
