<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
          <div class="modal"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header
              class="modal-header"
              id="modalTitle"
            >
              <slot name="header">
                <button
                type="button"
                class="confirm-exit-modal"
                @click="close"
                aria-label="Close modal"
              >
              <img src="@/assets/close-modal.png" alt="">
              </button> -->
                <p class="header">جهت واریز یکی از روش های زیر را انتخاب کنید.</p>
              </slot>
              <button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
              <!-- <img src="./closeModal.png" alt=""> -->
              </button>
            </header>
    
            <section
              class="modal-body"
              id="modalDescription"
            >
              <slot name="body">
                  <div class="peyment-choice" @click="toTransfer">
                    <img class="wallet" src="@/assets/wallet.png" alt="">
                    <p class="peyment-title">
                      انتقال
                    </p>
                  </div>
                  <div class="peyment-choice" @click="toOnline">
                    <img class="online-payment" src="@/assets/online-payment.png" alt="">
                    <p class="peyment-title">
                       پرداخت آنلاین
                    </p>
                  </div>
                  <div class="peyment-choice" @click="toCard">
                    <img class="buy-card" src="@/assets/buy-card.png" alt="">
                    <p class="peyment-title">
                        کارت به کارت
                    </p>
                  </div>
              </slot>
              
            </section>
    
            <footer class="modal-footer">
            </footer>
          </div>
        </div>
      </transition>
</template>

<script>

  export default {
    name: 'Buy',
    // props: {
    //   crypto:Object
    // },
    props : [
      'value'
    ],
    data() {
      return {
        depoData :{
          modal:false,
          openCard:false,
          openTransfer:false,
          openOnline:false
        }
      }
    },
    methods: {
      close() {
        this.$emit('fromDepo' , this.depoData);
      },
      toCard() {
        this.depoData.openCard = true
        this.depoData.openOnline = false
        this.$emit('fromDepo' , this.depoData)
        // this.depoData.openCard = false
      },
      toTransfer() {
        this.depoData.openTransfer = true
        this.$emit('fromDepo' , this.depoData)
      },
    toOnline() {
      this.depoData.openOnline = true
      this.depoData.openCard = false
        this.$emit('fromDepo' , this.depoData);
        // this.depoData.openOnline = false
    }
    },
    created() {
      window.addEventListener("click", (event) => {
      if (!event.target.closest(".modal , .toman_deposit_btn , .toman_withdrawal_btn")) {
         this.$emit('fromDepo' , this.depoData);
      }
    });
    }
  };
  
</script>

<style scoped>
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
     background-color: rgba(111, 111, 112, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .modal {
    background: linear-gradient(180deg, #15161A 0%, #15161A 100%);
/* shadow */

box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
border-radius: 5px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-height: 276px;
    max-width:610px ;
  }

  .modal-header {
    padding: 15px;
    display: flex;
    position: relative;
  }
  .header {
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    /* justify-content: center; */
    text-align: right;
    direction: rtl;
  }
  /* .modal-header > slot > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    font-family: shabnam;
  } */

  .modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
  }

  .modal-body {
    position: relative;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 50px;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: center;
  }
  .peyment-choice {
    width: 150px;
height: 160px;
background:rgba(255, 255, 255, 0.05);
border-radius: 5px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 10px;
  }
  .peyment-title {
    font-weight: bold;
font-size: 14px;
color: #FFFFFF;
  }
  .authen-warnning-img {
      display: flex;
      align-items: center;
  }
  /* .modal-body > slot > div > p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
    font-family: shabnam;
  } */
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
  }

  
  .cancel-exit-modal  {
    border-radius: 2px;
    width: 146px;
    height: 40px;
    border: 0;
    font-family: shabnam;
    font-weight: bold;
font-size: 16px;
  }
  .confirm-exit-modal {
    
    box-sizing: border-box;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
  }
  .cancel-exit-modal {
    background: #00ACC1;
    color: white;
  }
  .exit-message-modal > p {
    font-weight: 500;
    font-size: 24px;
    font-family: shabnam;
  }
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  .wallet , .online-payment , .buy-card{
    width: 120px;
    height: 110px;
  }
   @media only screen and (max-width:600px) {
    .modal {
      width: 250px;
      overflow: scroll;
      height: 600px;
    }
    .wallet , .online-payment , .buy-card{
    width: 60px;
    height: 50px;
  }
  .peyment-choice {
    height: 120px;
  }
  }
</style>