<template>
  <div class="container" :key="state.price.BTCUSDT.buying">
    <div class="tooman-teter">
      <div class="tooman" @click="active">
        <div :class="{ active: toomanActive }">
          تومان
        </div>
      </div>
      <div class="teter" @click="active">
        <div :class="{ active: teterActive }">
          تتر
        </div>
      </div>
    </div>
    <div class="table-container" >
      <table>
        <tr class="head-row">
          <td>
            <p class="title-p-box first-title">رمز ارز</p>
          </td>
          <td>
            <p class="title-p-box">تغییرات</p>
          </td>
          <td>
            <p class="title-p-box">
              قیمت خرید از ما
              <span v-if="toomanActive" class="title-sub-box">
                تومان
              </span>
              <span v-if="teterActive" class="title-sub-box">تتر</span>
            </p>
          </td>
          <td>
            <p class="title-p-box">
              قیمت فروش به ما
              <span v-if="toomanActive" class="title-sub-box">
                تومان
              </span>
              <span v-if="teterActive" class="title-sub-box">تتر</span>
            </p>
          </td>
          <!-- <td>
            <p class="title-p-box">
              قیمت جهانی<span class="title-sub-box"> تومان </span>
            </p>
          </td> -->
          <td>
            <p class="title-p-box">نمودار</p>
          </td>
        </tr>
        <tr class="row1" v-for="(crypto, index) in coinList" :key="index" >
          <td>
            <div class="first-td-crypto">
              <img class="box-image" :src=crypto.coin.logo alt="" />
              <p class="crypto-name">{{ crypto.coin.label }}</p>
              <p class="crypto-ENname">{{ crypto.coin.code }}</p>
            </div>
          </td>
          <td>
            <!-- <span v-if="price[`${crypto.code}USDT`].percentChange < 0">-</span> -->
            <span v-if="price[`${crypto.coin.code}USDT`]"
            :class="{
              'negative-change': +price[`${crypto.coin.code}USDT`].percentChange < 0,
              'positive-change': +price[`${crypto.coin.code}USDT`].percentChange > 0,
            }">
              {{ Math.abs(price[`${crypto.coin.code}USDT`].percentChange) }}
              <span v-if="+price[`${crypto.coin.code}USDT`].percentChange > 0">+</span>
              <span v-if="+price[`${crypto.coin.code}USDT`].percentChange < 0">-</span>
            </span>
            <!-- :class="{
              'negative-change': +price[`${crypto.code}USDT`].percentChange < 0,
              'positive-change': +price[`${crypto.code}USDT`].percentChange > 0,
            }" -->
            <!-- <sapn v-if="price[`${crypto.code}USDT`].percentChange > 0">+</sapn> -->
          </td>

          <td class="crypto-prices" v-if="toomanActive">
            <span v-if="price[`${crypto.coin.code}USDT`]">
              {{parseFloat(price[`${crypto.coin.code}USDT`].tomanBuying.toFixed(3)).toLocaleString()}}
            </span>
          </td>
          <td class="crypto-prices" v-if="teterActive">
            <span v-if="price[`${crypto.coin.code}USDT`]">
              {{parseFloat(price[`${crypto.coin.code}USDT`].buying.toFixed(3)).toLocaleString()}}
            </span>
          </td>
          <td class="crypto-prices" v-if="toomanActive">
            <span v-if="price[`${crypto.coin.code}USDT`]">
              {{ parseFloat(price[`${crypto.coin.code}USDT`].tomanSelling.toFixed(3)).toLocaleString()}}
            </span>
          </td>
          <td class="crypto-prices" v-if="teterActive">
            <span v-if="price[`${crypto.coin.code}USDT`]">
              {{ parseFloat(price[`${crypto.coin.code}USDT`].selling.toFixed(3)).toLocaleString()}}
            </span>
          </td>
          <!-- <td class="crypto-prices" >
            <span v-if="price[`${crypto.code}USDT`]">
              {{ price[`${crypto.code}USDT`].selling}}
            </span>
          </td> -->
          
          <!-- <td class="crypto-prices" v-if="this.state.price[crypto.code+'USDT'].price">
            {{ this.state.price[crypto.code+'USDT'].price.toLocaleString() }}
          </td> -->
          <td class="bitcoin-price" >
            <TrendChart :trend="trend[crypto.coin.code]" :key="crypto.coin.code + change" />
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import TrendChart from "./TrendChart.vue";
 import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
export default {
  name: "CryptoBox",
  components: {
    TrendChart,
  },
  data() {
    return {
      echo : "",
      toomanActive: true,
      teterActive: false,
      cryptoList: [],
      images: [],
      trend: [],
      price:[],
      coinList:[],
      chart: {},
      chartOb: {},
      aa: ["90", "20", "30"],
      change : 0,
      reloadd:false,
      reload:0,
      test : {
        test1 : {
          sell:1000
        }
      },
    };
  },
  props: ["value","reloaded"],
  methods: {
    active() {
      if (this.toomanActive) {
        this.toomanActive = false;
        this.teterActive = true;
      } else {
        this.toomanActive = true;
        this.teterActive = false;
      }
    },
    async chartData() {
      this.chart = await this.$axios.get(
        "/weekly-prices"
      );

      this.chart.data.data.forEach((element) => {
          this.chartOb[element.coin.code] = element.prices;
        
      });
      // console.log('aaaaa=>>>',this.chartOb)
      for (let key in this.chartOb) {
        this.trend[key] = Object.values(this.chartOb[key])
      }
      ++this.change
      // console.log("trend", this.trend);
      // console.log("ob", this.chartOb);
    },
    //  reload() {
    //   setTimeout(this.forceReload , 2000);
      
    // },
    // forceReload() {
    //   // this.reloadd = !this.reloadd
    //   // console.log('rygi7yer7gywr8e7gtre7',this.reloadd)
    //   // this.coinList = this.value
    // },
    async getCrypto() {
      // this.aboutPrice()
          this.state.loading = true
          this.price=this.state.price
          console.log('this.price',this.price)
          // "http://adatether.6.vistadev.ir/api/markets?status=ACTIVATED&size=500"
          // http://adatether.6.vistadev.ir/api/coins/public
                 this.cryptoList = await this.$axios.get('/markets?status=ACTIVATED&size=500')
                 for(var i=0 ; i <= this.cryptoList.data.data.length-1 ; i++) {
                     if(this.cryptoList.data.data[i].code=='TOMAN') {
                         this.cryptoList.data.data.splice(i , 1)
                     }
                     
                 }
                //  this.cryptoList.data.data.forEach(el => {
                //      for(var i=0 ; i <=this.state.price.prices.length-1; i++ ) {
                //          if(el.code+'USDT'==this.state.price.prices[i].market) {
                //             el.buy_price=this.state.price.prices[i].buying;
                //             el.percent_change=this.state.price.prices[i].percentChange;
                //             el.toman_buying=this.state.price.prices[i].tomanBuying;
                //             el.toman_selling=this.state.price.prices[i].tomanSelling;
                //          }
                //      }
                //  });
                 
                this.coinList = this.cryptoList.data.data
                console.log('this.cryptoList',this.coinList)
                // ++this.reload
                // console.log('++this.reload',++this.reload);
                this.state.loading = false
            },
            // aboutPrice() {
            //   setTimeout(this.setPrice() , 5000)
            // },
            // setPrice() {
            //   this.price=this.state.price
            // }
  },
  watch : {
    price:function(newVal , oldVal) {
      console.log('val' , newVal , oldVal)
    }
  },
  // computed() {
  //   this.price=this.state.price
  // },
  created() {

    Pusher

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      wsHost: '185.110.189.39',
      wsPort: 6001,
      forceTLS: false,
      cluster: 'mt1',
      disableStats: true,
    });
 this.echo.channel(`realtime-price`)
    .listen('RealTimePriceEvent', (e) => {
      e.prices.forEach(element => {
        this.state.price[element.market] = element
       this.state.price=e;
      });
      this.price=e;
      this.state.reload = !this.state.reload
      // console.log('prices',this.state.price)
        
        // console.log('price in state',this.state.price.prices[1].market)
    });
  },
  mounted() {
    this.chartData();
    // this.price=this.state.price
    // this.aboutPrice()
    console.log('hello', this.price)
    this.getCrypto()
    // this.reload()
    
  },
};
</script>

<style scoped>
.bitcoin-price {
  max-width: 50px;
  max-height: 60px !important;
}
.container {
  /* margin-top: 80px; */
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
}
.table-container {
  width: 100%;
  overflow-x: scroll;
}
.table-container::-webkit-scrollbar {
  display: none;
}

.table-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  min-width: 800px;
}
tr:nth-child(odd) {
  background: #2f3239;
}
tr:nth-child(even) {
  background: #272a30;
}
.first-td-crypto {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.title-p-box {
  color: rgba(255, 176, 7, 1);
  font-weight: 500;
  /* font-size: 16px; */
  font-size: clamp(14px, 1vw, 16px);
}
.first-title {
  text-align: right;
  margin-right: 70px;
}
td:first-child {
  padding: 0 60px 0 0;
}
td:last-child {
  padding: 0 0 0 30px;
}
.row1 {
  max-height: 62px !important;
}
.box-image {
  width: 40px;
  height: 40px;
}
.negative-change {
  color: rgba(255, 46, 33, 1);
  font-weight: 500;
  /* font-size: 18px; */
  font-size: clamp(14px, 1vw, 18px);
  direction: ltr;
}
.positive-change {
  color: rgba(15, 169, 2, 1);
  font-weight: 500;
  /* font-size: 18px; */
}
.title-sub-box {
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 156, 7, 0.6);
}
.crypto-name {
  color: #ffffff;
  font-weight: 500;
  /* font-size: 16px; */
  text-align: right;
}
.crypto-ENname {
  font-weight: 500;
  /* font-size: 16px; */
  color: rgba(255, 255, 255, 0.6);
}
.crypto-name,
.crypto-ENname {
  font-size: clamp(14px, 1.5vw, 16px);
}
.crypto-prices {
  color: #ffffff;
}
.tooman-teter {
  background: #2f3239;
  display: flex;
  width: 213px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.tooman,
.teter {
  background-color: rgba(21, 22, 26, 0.8);
  width: 101px;
  height: 35px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active {
  background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
  border-radius: 5px;
  width: 98px;
  height: 27px;
  color: rgba(21, 22, 26, 1);
}

@media only screen and (max-width: 900px) {
  td:first-child {
    padding: 0 20px 0 0;
  }
  td:last-child {
    padding: 0 0 0 20px;
  }
}
</style>
