<template>
    <div class="orders" >
        <div class="orders-option">
            <div class="orders-option-container">
                <div class="active-orders" :class="{'active-option':active}" @click="activeOrders">
                    سفارشات فعال
                </div>
                <div class="orders-history" :class="{'active-option':history}" @click="activeHistory">
                    تاریخچه معاملات
                </div>
            </div>
        </div>
        <div class="table-container">
        <table v-if="active" :key="addOrder">
            <tr class="tr-head">
                <td>
                    شماره سفارش
                </td>
                <td>
                    نوع سفارش
                </td>
                <td>
                    رمز ارز
                </td>
                <td>
                    مقدار
                </td>
                <td>
                    قیمت واحد <span class="price-detail"> تومان </span>
                </td>
                <td>
                    قیمت کل <span class="price-detail"> تومان </span>
                </td>
                <td>
                    زمان
                </td>
                <td>
                    وضعیت
                </td>
                <td>
                    حدف
                </td>
            </tr>
            <template v-for="(Orders , index) in OrdersList" >
            <tr v-if="Orders.status=='PENDING' && OrdersList" :key="index" class="tr-body" >
                <td>
                    {{Orders.id}}
                </td>
                <td :class="{'type-sell':Orders.type==='SELL' , 'type-buy':Orders.type==='BUY'}">
                    <span v-if="Orders.type==='BUY'">
                        خرید
                    </span>
                    <span v-if="Orders.type==='SELL'">
                        فروش
                    </span>
                </td>
                <td>
                    {{Orders.market.coin.label}}
                </td>
                <td>
                     {{Orders.amount}}
                </td>
                <td>
                     {{Orders.price}}
                </td>
                <td>
                     {{Orders.total.toLocaleString()}}
                </td>
                <td>
                    {{$G2J(Orders.created_at, 'faDateTime')}}
                </td>
                <td >
                    در حال بررسی
                </td>
                <td >
                    <img src="@/assets/trash.png" alt="" @click="cancelRequest(index)">
                </td>
            </tr>
            </template>
        </table>
        <table v-if="history" :key="addOrder">
            <tr class="tr-head">
                <td>
                    شماره سفارش
                </td>
                <td>
                    نوع سفارش
                </td>
                <td>
                    رمز ارز
                </td>
                <td>
                    مقدار
                </td>
                <td>
                    قیمت واحد <span class="price-detail"> تومان </span>
                </td>
                <td>
                    قیمت کل <span class="price-detail"> تومان </span>
                </td>
                <td>
                    زمان
                </td>
                <!-- <td>
                    پیگیری
                </td> -->
                <td>
                    وضعیت
                </td>
            </tr>
            <template v-for="(Orders , index) in OrdersList">
            <tr v-if="Orders.status !='PENDING' && OrdersList" :key="index" class="tr-body">
                <td>
                     {{Orders.id}}
                </td>
                <td :class="{'type-sell':Orders.type==='SELL' , 'type-buy':Orders.type==='BUY'}">
                  <span v-if="Orders.type==='BUY'">
                        خرید
                    </span>
                    <span v-if="Orders.type==='SELL'">
                        فروش
                    </span>
                </td>
                <td>
                    {{Orders.market.coin.label}}
                </td>
                <td>
                     {{Orders.amount}}
                </td>
                <td>
                     {{Orders.price}}
                </td>
                <td>
                     {{Orders.total.toLocaleString()}}
                </td>
                <td>
                     {{$G2J(Orders.created_at, 'faDateTime')}}
                </td>
                <!-- <td>
                    
                </td> -->
                <!-- <td v-if="Orders.status=='ACCEPTED'">
                    <img src="@/assets/status-done.png" alt="">
                </td> -->
                <td :class="{'status2' : Orders.status != 'ACCEPTED'}" >
                    <img v-if="Orders.status=='ACCEPTED'" src="@/assets/status-done.png" alt="">
                     <img v-else src="@/assets/status-fail.png" alt=""> 
                </td>
            </tr>
            </template>
        </table>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Orders',
  components :{
    
  },
  props:[
      'addOrder'
  ],
  data() {
      return {
          OrdersList:[],
          active:true,
          history:false,
          
      }
  },
  methods: {
      activeOrders() {
          this.addOrder = !this.addOrder
          if(!this.active) {
              this.active=true
              this.history=false
          }
          else
          return true
      },
      activeHistory() {
          this.addOrder = !this.addOrder
          if(!this.history) {
              this.history=true
              this.active=false
          }
          else
          return true
      },
      async ActiveOrders() {
          const res = await this.$axios.get(
              "/orders",
            {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          this.OrdersList=res.data.data
          console.log('order',this.OrdersList)
      },
      async cancelRequest(index) {
          this.state.loading = true
          try {
              await this.$axios.patch(
              "/orders/"+this.OrdersList[index].id+"/cancel",
              null,
            {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          }
          catch(err) {
              const res = err.response || err.toJSON();
              console.log(res);
          }
          
          this.state.loading = false
      }
  },
  mounted() {
      this.ActiveOrders()
  }
  }
</script>

<style scoped>
    .orders {
        width: 100%;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.3));
    }
    .orders-option {
        /* width: 300px; */
        height: 40px;
        background: #2F3239;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
    }
    .orders-option-container {
        width: 290px;
        height: 35px;
        background:rgba(21, 22, 26, 0.8);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .active-orders , .orders-history {
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        cursor: pointer;
        margin: 0 5px;
        font-size: clamp(12px , 1vw , 16px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 125px;
    }
    .active-option {
        width: 125px;
        font-weight: 600;
        height: 27px;
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border-radius: 5px;
        color: rgba(21, 22, 26, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        background: #2F3239;
        text-align: center;
        min-width: 700px;
    }
    tr:nth-child(odd) {
        background: #2F3239
    }
    tr:nth-child(even) {
        background: #272A30
    }
    .tr-head {
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 156, 7, 1);
        height: 60px;
        height: 60px;
        font-size: clamp(12px , 1vw , 16px);
    }
    .tr-body {
        height: 40px;
        font-weight: normal;
        font-size: 16px;
        color: #FFFFFF;
        height: 60px;
        font-size: clamp(12px , 1vw , 16px);
    }
    .type-sell {
        color: #FF2E21;
    }
    .type-buy {
        color: #0FA902;

    }
    .status2 {
        color: #0FA902;
    }
    .price-detail {
        font-weight: normal;
       font-size: 14px;
       color: rgba(255, 156, 7, 0.6);
    }
    .table-container {
        overflow: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;  
    }
    .table-container::-webkit-scrollbar {
  display: none;
}
</style>
