<template>
    <div>
        <p class="tickets-title">آخرین اعلان ها</p>
        <div class="table-container">
        <table v-if="AnnouncementList != ''">
            <tr  v-for="(Announcement , index) in AnnouncementList" :key="index">
                <a href="/notification" @click.prevent="reade(index)" >
                    <td :class="{'read' : is_read===true}"> 
                        <img src="@/assets/dote-yellow.png" alt="">
                        {{Announcement.title}}
                    </td>
                </a>
            </tr>
        </table>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Announcement',
  components :{

    
  },
  data() {
      return {
          AnnouncementList:[]
      }
  },
  methods : {
      async notification() {
          this.state.loading = true
          const res = await this.$axios.get(
              '/notifications',
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
            this.AnnouncementList = res.data.data
            this.state.loading = false
      },
      async reade(index) {
          await this.$axios.patch(
              `/notifications/${this.AnnouncementList[index].id}`,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
          this.$router.push('/notification')
      }
  },
  mounted() {
      this.notification()
  }
  }
</script>

<style scoped>

    .tickets-title {
        font-weight: 500;
font-size: 18px;
color: #DBABA7;
    }
    table {
        width: 100%;
         
    }
    .table-container {
        height: 320px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }
    .table-container::-webkit-scrollbar {
        display: none;
    }
    .tr-head {
        font-weight: 500;
font-size: 16px;

color: rgba(255, 156, 7, 1);
    }
    tr{
        height: 40px;
        color: #FFFFFF;
        font-size: clamp(10px , 1.5vw ,16px);
    }
    td {
        padding-right: 20px;
    }
    
     tr:nth-child(odd) {
        background: #2F3239;
    }
    tr:nth-child(even) {
        background: #272A30;
    }
    .read {
        opacity: 0.7;
    }
    a {
        color: white;
    }
</style>
