<template>
  <div class="login">
    <div class="login-form-title">فراموشی رمز عبور</div>
    <div>
      <hr />
    </div>
    <!-- <form @submit.prevent="inputCode"> -->
      <div>
        <label for="">
          ایمیل
          <input type="text" v-model="data.email" disabled />
        </label>
      </div>
      <div>
        <label for="">
          <span class="second">{{ this.second }}</span>
          کد ارسال شده به ایمیل
          <input type="text" @input="checkCode" v-model="data.code" />
        </label>
        <p class="error-p" v-if="ErrorText.length">
            {{ErrorText}}
        </p>
      </div>
      <div>
        <button class="login-btn" @click="send">تایید کد</button>
      </div>
    <!-- </form> -->
  </div>
</template>

<script>
export default {
  name: "EmailOTP",
  components: {},
  data() {
    return {
      second: 60,
      //   emailCode:null
      data: {
        email: this.state.email,
        code: null,
      },
      ErrorText:""
    };
  },
  methods: {
      inputCode(e) {
          //   while(this.second > 0) {
              setTimeout(() => {

                  while(this.second > 0) {

              this.second -= 1

               this.inputCode()

               }}, 1000);

          //    }
          if(this.second==0) {
            this.reSend()
          }
                e.preventDefault();

        // alert("kljk")
      },
    async send() {
        try {
            const res = await this.$axios.post(
        "/forget-password-confirm",
        this.data
      );
      if (res.data.type == 'success') {
        this.$router.push("/change-pass");
      }
        }
        catch(err) {
            const res = err.response || err.toJSON();
            // console.log(res.data.errors.email);
            this.ErrorText = res.data.errors.email[0];
        }

    },
    async reSend() {
      try {
            const res = await this.$axios.post(
        "/resend-otp",
        this.data
      );
      if (res.data.type == 'success') {
        this.$router.push("/change-pass");
      }
        }
        catch(err) {
            const res = err.response || err.toJSON();
            // console.log(res.data.errors.email);
            this.ErrorText = res.data.errors.email;
        }
    }
  },
  mounted() {
    this.data.email = this.state.email;
    this.inputCode()
  },
};
</script>

<style scoped>
.login,
form {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.login-form-title {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(90deg, black, white, black);
}
label,
input {
  width: 100%;
  /* background: #212225; */
  border-radius: 5px;
  border: 0;
}
label {
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  position: relative;
}
input {
  height: 40px;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.04);
}
.login-btn {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(180deg, #ffb007 0%, #ff9c07 100%);
  border: 0;
  font-weight: 500;
  font-size: 16px;
}
.sub-link {
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}
.link-p {
  text-decoration-line: underline;
  color: #53acff;
}
.google-btn {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  color: #ffffff;
  height: 50px;
}
.forget-a {
  margin: 0;
  text-align: left;
}
.forget-a > a {
  text-decoration-line: underline;
  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
}
.second {
  border: 0;
  background: #2b2d30;
  position: absolute;
  width: 45px;
  left: 1px;
  top: 26px;
  height: 37px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
</style>
