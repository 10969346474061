import Vue from "vue";
// import router from '../rpoter/index'

let that = Vue.prototype

// that.$setToken = (token, remember = true) => {
//     if (remember) {
//         localStorage.token = 'Bearer ' + token
//         that.state.token = localStorage.token
//     } else {
//         Vue.$cookies.set('--abcd14589', 'Bearer ' + token, 'session')
//         that.state.token = Vue.$cookies.get('--abcd14589')
//     }
//     that.state.gotten = false
//     that.$axios.defaults.headers.common['Authorization'] = that.state.token
// }

that.$validPass = (password) => {
    let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
    return re.test(password);
}

that.$validEmail = (email) => {
    var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(email);
}
that.$validPass = (password) => {
    let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
    return re.test(password);
}
that.$detectOs = () => {
    let Name = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") > -1) Name =
        "WINDOWS";
    if (navigator.userAgent.indexOf("Mac") > -1) Name =
        "MACINTOSH";
    if (navigator.userAgent.indexOf("Linux") > -1) Name =
        "LINUX";
    if (navigator.userAgent.indexOf("Android") > -1) Name =
        "ANDROID";
    if (navigator.userAgent.indexOf("like Mac") > -1) Name = "IOS";
    // console.log('Your OS: ' + name);
    return Name
}
that.$detectBrowser = () => {
    // Opera 8.0+
    let browserType
    let isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera) {
        browserType = 'OPERA'
    }

    // Firefox 1.0+
    let isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
        browserType = 'FIREFOX'
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if (isSafari) {
        browserType = 'SAFARI'
    }
    // Internet Explorer 6-11
    let isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) {
        browserType = 'IE'
    }
    // Edge 20+
    let isEdge = !isIE && !!window.StyleMedia;
    if (isEdge) {
        browserType = 'EDGE'
    }
    // Chrome 1 - 79
    let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
        browserType = 'CHROME'
    }
    // Edge (based on chromium) detection
    let isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    if (isEdgeChromium) {
        browserType = 'EDGECHROMIUM'
    }
    // console.log('browserType : ' + browserType)
    return browserType
}
that.$detectDevice = () => {
    let deviceType
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = 'TABLET'
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        deviceType = 'MOBILE'
    } else {
        deviceType = 'DESKTOP'
    }
    // console.log(deviceType)
    return deviceType
}
that.$toLocal = (number, decimal = 8) => {
    //purify number
    number = number + ''
    if (number === '.') {
        if (decimal)
            return '0.'
        else
            return ''
    }

    if (number.startsWith('-'))
        return 0

    if (number.toLowerCase().includes('e')) {
        if (parseFloat(number) < 1.0e-7) {
            //zero numbers
            return 0
        } else
            number = parseFloat(number) + ''
    }

    number = that.$p2e(number).replace(/[^\d.]|\.{2}|,/ig, '')
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '')
    }

    if (parseFloat(number)) {
        //separate number
        const int = parseFloat(number.split('.')[0]).toLocaleString()
        if (number.includes('.') && decimal) {
            return int + '.' + (number.split('.')[1].slice(0, decimal) || '')
        } else {
            return int
        }
    } else {
        return number
    }

}
