<template>
    <div class="security">
        <EmailTwoStep v-if="modal.email" @closeModal="modal.email=false"/>
        <SMSTwoStep v-if="modal.sms" @closeModal="modal.sms=false"/>
        <GoogleTwoStep v-if="modal.google" @closeModal="modal.google=false"/>
        <div >
           <p class="security-title">
               امنیت
            </p> 
        </div>
        <div class="two-step">
            <div class="two-step-title">
                شناسایی دو عاملی
            </div>
            <div class="steps-container" :key="setting.setting_value">
                <label  v-for="(input , index) in inputs" :key="index" class="setting_label">
                    <input class="checkbox" @change="showModal(index)" type="radio" :checked="setting.setting_value==input.value" :value="input.value"/>
                    <p class="two-step-text" >{{input.title}}</p>  
                </label>
                <label class="setting_label">
                    <input class="checkbox" @change="getGoogle" value='GOOGLE' type="radio" :checked="setting.setting_value=='GOOGLE'">
                    <p class="two-step-text" >ورود دو مرحله ای با گوگل</p>  
                </label>
            </div>
        </div>
        <div class="change-pass">
            <div class="change-pass-title">
                تغییر رمز عبور
            </div>
            <div class="inputs-container">
                <div class="input">
                    <label for="">
                        <button class="show-pass" @click="showPassword1" >
                            <img class="show-pass-image" src="@/assets/showpass.png" >
                        </button>
                        رمز عبور قبلی
                        <input :type="type1" v-model="changeData.password_old">
                    </label>
                </div>
                <div class="input">
                    <label for="">
                        <button class="show-pass" @click="showPassword2" >
                            <img class="show-pass-image" src="@/assets/showpass.png" >
                        </button>
                        رمز عبور جدید
                        <input :type="type2" v-model="changeData.password">
                    </label>
                </div>
                <div class="input">
                    <label for="">
                        <button class="show-pass" @click="showPassword3" >
                            <img class="show-pass-image" src="@/assets/showpass.png" >
                        </button>
                        تکرار رمز عبور جدید
                        <input :type="type3" v-model="changeData.password_confirmation">
                    </label>
                </div>
            </div>
            <div class="change-btn-container">
                <button class="change-btn" @click="changePass" :disabled="!changeData.password_old || !changeData.password || !changeData.password_confirmation">تغییر رمز عبور</button>
            </div>
        </div>
    </div>
</template>

<script>
import EmailTwoStep from './EmailTwoStep.vue'
import SMSTwoStep from './SMSTwoStep.vue'
import GoogleTwoStep from './GoogleTwoStep.vue'
export default {
  name: 'security',
  components :{
    EmailTwoStep,
    SMSTwoStep,
    GoogleTwoStep
  },
  data() {
      return {
          type1: 'password',
          type2: 'password',
          type3: 'password',
          modal : {
              email:false,
          sms:false,
          google:false,
          },
          method: 'EMAIL',
          disable:true,
          setting_value:null,
          inputs : [
              {
                  value:"SMS",
                  title:"ورود دو مرحله ای با پیامک"
              },
              {
                  value:"EMAIL",
                  title:"ورد دو مرحله ای با ایمیل"
              },
              
          ],
          pass: {

          },
         setting: {
            setting_key : "OTP",
            setting_value : ""
        },
          changeData:{
              password_old:'',
              password:'',
              password_confirmation:''
          },
          otp_value : {
              setting_key : '',
            //   setting_value : ''
          }
      }
  },
  methods: {
    //   closeModals(value) {
    //       this.email=value
    //       this.sms=value
    //       this.google=value
    //   },
      async getInfo() {
          const info =await this.$axios.get(
              "/settings/otp",
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
          )
         
          this.setting = info.data.data
          this.setting_value = info.data.data.setting_value
        //    console.log('iiiiiiiiiiiiii',info , this.setting_value)
      },
       async showModal(index) {
           this.state.loading = true
           if(index == 0) {
               this.modal.sms=true
               this.otp_value.setting_key = 'SMS'
           }
           if(index == 1) {
               this.modal.email=true
               this.otp_value.setting_key = 'EMAIL'
           }
           if(index == 2) {
               this.modal.google=true
               this.otp_value.setting_key = 'GOOGLE'
           }
         await this.$axios.post("/settings/otp",this.otp_value ,
                 {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
            this.state.loading = false
      },
      async getGoogle() {
        //   this.state.loading = true
           this.modal.google=true
        //    await this.$axios.get("https://adatether.6.vistadev.ir/api/settings/google-2fa",
        //          {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
        //     this.state.loading = false
      },
      SmsModal() {
          this.sms=true
      },
      googleModal() {
          this.google=true
      },
       showPassword1(e) {
          if(this.type1 === 'password') {
				this.type1 = 'text'
				this.btnText = 'Hide Password'
			} else {
				this.type1 = 'password'
				this.btnText = 'Show Password'
			}
            e.preventDefault();
      },
      showPassword2(e) {
          if(this.type2 === 'password') {
				this.type2 = 'text'
				this.btnText = 'Hide Password'
			} else {
				this.type2 = 'password'
				this.btnText = 'Show Password'
			}
            e.preventDefault();
      },
      showPassword3(e) {
          if(this.type3 === 'password') {
				this.type3 = 'text'
				this.btnText = 'Hide Password'
			} else {
				this.type3 = 'password'
				this.btnText = 'Show Password'
			}
            e.preventDefault();
      },
    // settingFun(index) {
    //     this.setting.setting_value=this.inputs[index].value
    // },
        async changePass() {
            if(this.changeData.password_old || this.changeData.password || this.changeData.password_confirmation) {
            const settingsPass = await this.$axios.post("/settings/password",this.changeData,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
                console.log('settingsPass',settingsPass)
                console.log(settingsPass)
            console.log('pass',this.changeData)
        }
        },
        async settingValue(index) {
            this.setting.setting_value=this.inputs[index].value
            const res = await this.$axios.post(
                '/settings',
                this.setting ,
                {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}}
            )
            console.log(res)
        }
  },
  computed: {
      logg(){
          return console.log(this.changeData.password_old)
          }
  },
//   computed() {
//       for (const [key, value] of Object.entries(this.changeData)) {
//         console.log(`${key}: ${value}`);
// }
//   },
  mounted() {
      this.getInfo()
    // const settings = this.$axios.get("https://adatether.6.vistadev.ir/api/settings",
    //     {headers: {'Authorization': `Bearer ${this.$cookies.get('token')}`}})
        // this.setting = settings
    //     console.log('sttings' , settings)
    // const settingsPost = this.$axios.post("https://adatether.6.vistadev.ir/api/settings",
    //     {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //     console.log('settingsPost',settingsPost)
    // const settingsOTP = this.$axios.get("https://adatether.6.vistadev.ir/api/settings/otp",
    //     {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //     console.log('settingsOTP',settingsOTP)
    // const settingsPass = this.$axios.post("https://adatether.6.vistadev.ir/api/settings/password",
    //     {headers: {'Authorization': `Bearer ${this.state.token}`}})
    //     console.log('settingsPass',settingsPass)
}
  }
</script>

<style scoped>
    .security {
        width: 95%;
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: 30px;
    }
    .security-title {
        font-weight: 500;
        font-size: 20px;
        color: #DBABA7;
        margin-bottom: 0;
    }
    .two-step , .change-pass {
        width: 100%;
        background: rgba(21, 22, 26, 0.7);
        border-radius: 5px;
    }
    .two-step-title , .change-pass-title {
        font-weight: 500;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        height: 35px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .steps {
        display: flex;
        column-gap: 10px;
        align-items: center;
    }
    .checkbox {
        width: 24px;
        height: 24px;
        background: #FFFFFF;
        border-radius: 5px;
        border: none;
    }
    .checkbox:checked  {
        background-color: black
    }
    .two-step-text {
        font-weight: 500;
        /* font-size: 16px; */
        font-size: clamp(14px , 1.5vw , 16px);
        color: #FFFFFF;
    }
    .steps-container {
        display: flex;
        column-gap: 100px;
        width: 95%;
        margin: auto;
       min-height: 100px;
    }
    .inputs-container {
        width: 95%;
        margin: auto;
        display: flex;
        min-height: 150px;
        align-items:center;
        column-gap: 40px;
    }
    input {
        width: 100%;
        background: rgba(255, 255, 255, 0.04);
        border-radius: 5px;
        height: 40px;
        border: none;
         color: rgba(255, 255, 255, 0.7);
    }
    .input {
        display: flex;
        flex-grow: 1;
       
    }
    label {
        font-weight: normal;
        /* font-size: 16px; */
        font-size: clamp(14px , 1.5vw , 16px);
        color: #FFFFFF;
        width: 100%;
        position: relative;
    }
    .change-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 70px;
    }
    .change-btn {
        background: linear-gradient(180deg, #FFB007 0%, #FF9C07 100%);
        border-radius: 5px;
        width: 335px;
        height: 40px;
        border: none;
        font-weight: 500;
        font-size: 16px;
    }
    .show-pass {
        border: none;
        background: transparent;
        position: absolute;
        left: 5px;
        top: 35px;
    }
    .setting_label {
        display: flex;
        align-items: center;
        column-gap: 5px;
    }
    @media only screen and (max-width:1000px) {
        .steps-container , .inputs-container {
            flex-wrap: wrap;
            row-gap: 20px;
        }
    }
    @media only screen and (max-width:600px) {
        .checkbox {
            width: 18px;
        }
    }
</style>
