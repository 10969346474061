<template>
  <div class="carosel-container">
    <carousel rtl='true' perPage=4>
    <slide v-for="(crypto , index) in cryptoList" :key="index">
      <div class="slide">
        <div class="img-name-price">
          <div>
            <img class="image-slide" :src=crypto.coin.logo alt="">
          </div>
          <div class="name-price">
            <div class="name">
              <p class="name-p">{{crypto.coin.label}}</p>
              <p class="name-p">{{crypto.coin.code}}</p>
            </div>
            <div class="price">
              <p class="price-p" v-if="price[`${crypto.coin.code}USDT`]">
                {{ parseFloat(price[`${crypto.coin.code}USDT`].tomanSelling.toFixed(3)).toLocaleString()}}
              <span> تومان </span>
              </p>
            </div>
          </div>
        </div>
        <div class="change">
          <p v-if="price[`${crypto.coin.code}USDT`]"
          :class="{
              'negative-change': +price[`${crypto.coin.code}USDT`].percentChange < 0,
              'positive-change': +price[`${crypto.coin.code}USDT`].percentChange > 0,
            }">
            {{ Math.abs(price[`${crypto.coin.code}USDT`].percentChange) }}
            <sapn v-if="+price[`${crypto.coin.code}USDT`].percentChange > 0">+</sapn>
            <sapn v-if="+price[`${crypto.coin.code}USDT`].percentChange < 0">-</sapn>
          </p>
        </div>
      </div>
    </slide>
  </carousel>
</div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js'
// import crypto from "../../library/crypto.js"
export default {
  name: 'slider',
  components :{
    Carousel,
    Slide,
    
  },
  props: [
    'value'
  ],
  data() {
    return {
      cryptoList : this.value,
      price:[],
    }
  },
  created() {

    Pusher

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      wsHost: '185.110.189.39',
      wsPort: 6001,
      forceTLS: false,
      cluster: 'mt1',
      disableStats: true,
    });
 this.echo.channel(`realtime-price`)
    .listen('RealTimePriceEvent', (e) => {
      e.prices.forEach(element => {
        this.price[element.market] = element
       this.price=e;
      });
      this.price=e;
      this.state.reload = !this.state.reload
      // console.log('prices',this.state.price)
        
        // console.log('price in state',this.state.price.prices[1].market)
    });
  },
  mounted() {
    //  this.price=this.state.price
  }
}
// Check if the request came from the browser and is not server rendered

</script>
<style scoped>
.carosel-container {
  background: linear-gradient(180deg, rgba(39, 42, 51, 0.2), #363941);
}
  .slide {
    background: rgba(255, 255, 255, 0.04);
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    width: 305px;
    height: 91px;
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
    /* background: linear-gradient(180deg, #272A33, #363941); */
  }
  @supports not ( backdrop-filter: blur(10px)) {
    .slide {
        background: #252833 !important;
    }
  }
  .img-name-price {
    display: flex;
    column-gap: 10px;
    align-items: center;
    /* padding: 10px; */
  }
  .image-slide {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  .name-price {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .name {
    display: flex;
    column-gap: 5px;
    align-items: center;
  }
  .name p , .price p{
    margin: 0;
  }
  .name-p {
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;

  }
  .price-p {
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);

  }
  .change {
    padding: 10px;
  }
  .negative-change , .positive-change {
    font-weight: 500;
    font-size: 18px;
    margin: 15px 0 0 5px;
  }
  .negative-change {
    color: rgba(255, 46, 33, 1);
  }
  .positive-change {
    color:rgba(15, 169, 2, 1);
  }
  
</style>
<style>
  .VueCarousel-inner {
    flex-basis: 325px !important;
  }
  .VueCarousel {
    direction: revert !important;
    width: 90%;
    margin: auto;
  }
  .VueCarousel-pagination {
    height: 10px !important;
  }
  .VueCarousel-dot {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    border: none;
    background-clip: content-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;
    background-color: black !important;
    margin-top: 0 !important;
}
.VueCarousel-dot--active {
  background-color: gainsboro !important;
}
.VueCarousel-dot-container {
  margin-top: 10px !important;
  display: none 1 !important;
}
.VueCarousel-wrapper {
  /* background: linear-gradient(180deg, rgba(39, 42, 51, 0.2), #363941); */
  /* background: linear-gradient(180deg, rgba(29, 32, 41, 0.2), #3f4147); */
}

</style>
